<template>
  <v-container fluid grid-list-md class="grey lighten-5">
    <v-row>
      <v-flex xs12 sm12 md8 lg8 ex8>
        <v-card ref="form">
          <v-card-text>
              <v-row no-gutters>
                <v-flex xs="12" class="py-0 px-2">                
                    <h2>{{lang.MainLedgerAnalysis}}</h2>
                </v-flex>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.CompanyName}}
                </v-col>
                <v-col xs="4" class="py-0 px-2">
                    <span style="font-weight:bold">{{mainledgerAna.CompanyNameDatabase}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                    {{lang.BusinessYear}}
                </v-col>
                <v-col xs="4" class="py-0 px-2">
                    <span style="font-weight:bold">{{mainledgerAna.BusinessYear}}</span>
                </v-col>
              </v-row>

              <v-row no-gutters>
                <v-col xs="4" class="py-0 px-2">
                  {{lang.User}}
                </v-col>
                <v-col xs="8" class="py-0 px-2">
                    <span style="font-weight:bold">{{mainledgerAna.First}} {{mainledgerAna.Last}}, {{mainledgerAna.email}}, {{mainledgerAna.UserId}}</span>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <br>

          <v-row>
              <v-col xs="3" >
                <v-dialog
                  ref="dialog1"
                  persistent
                  v-model="modal1"
                  width="290px"
                  :return-value.sync="startDate"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate1"
                      :label="langC.FromDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
          
                    ></v-text-field>
                  </template>

                  <v-date-picker v-model="startDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal1 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog1.save(startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

              <v-col xs="3" >
                <v-dialog
                  ref="dialog2"
                  persistent
                  v-model="modal2"
                  width="290px"
                  :return-value.sync="endDate"
                >
                 <template v-slot:activator="{ on }">
                    <v-text-field
                      :value="formatDate2"
                      :label="langC.ToDate"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
 
                  <v-date-picker v-model="endDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="modal2 = false">Cancel</v-btn>
                    <v-btn color="primary" @click="$refs.dialog2.save(endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>

               <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2"  >
                  <v-select
                  v-model="selectedMonth"
                  :items="months"
                  label="Mjesec"
                  clearable
                ></v-select>
              </v-col>
              <v-col xs="3" >
              </v-col>


          </v-row>           

            <v-row >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mt-2" >
                   {{lang.FromAccount}}
                </v-col>
                <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pa-0" >
                  <v-autocomplete
                    :items="account4Loc"
                    v-model="selectA4"
                    :label="lang.FromAccount"
                    item-text="acc4Acc"
                    item-value="account4"
                    return-object
                    persistent-hint
                    :filter="getList"
                    clearable
                  ></v-autocomplete>
                </v-col>

              </v-row>

              <v-row >
                <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mt-2" >
                   {{lang.ToAccount}}
                </v-col>
                <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0" >
                   <v-checkbox v-model="sameAsFrom"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pa-0" >
                  <v-autocomplete
                    :items="account4LocTo"
                    v-model="selectA4to"
                    :label="lang.ToAccount"
                    item-text="acc4Acc"
                    item-value="account4"
                    return-object
                    persistent-hint
                    :filter="getList"
                    clearable
                  ></v-autocomplete>
                </v-col>
              </v-row>

              <v-row >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mt-2" >
                  {{ langC.Partner }}                   
                </v-col>
                <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pa-0" >
                    <v-autocomplete
                      :items="partners"
                      v-model="selectPa"
                      :label="langC.SelectPartner"
                      item-text="partnerName"
                      item-value="id"
                      return-object
                      persistent-hint
                      clearable
                    ></v-autocomplete>
                </v-col>
              </v-row>

                <v-row >
                  <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mt-2" >
                    {{ lang.BookTypeAll }}
                  </v-col>
                  <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pa-0" >
                    <v-autocomplete
                      :label="lang.BookTypeSelect"
                      :items="bookType"
                      v-model="bookTypeSelected"
                      value="bookType.value"
                      return-object
                      persistent-hint
                      clearable
                      dense
                      ></v-autocomplete>
                  </v-col>
                </v-row>

              <v-row >
                <v-col cols="12" sm="3" md="3" xs="3" lg="3" xl="3" class="mt-2" >
                  {{ lang.MainLedgerProjectTypeName }}                   
                </v-col>
                <v-col cols="12" sm="9" md="9" xs="9" lg="9" xl="9" class="pa-0" >
                  <v-autocomplete
                    :label="lang.MainLedgerProjectTypeName"
                    :items="projectTypes"
                    v-model="selectPT"
                    item-text="TypeName"
                    item-value="id"
                    return-object
                    @change="choosedProjectType(selectPT)"
                    clearable
                    class="pr-2"></v-autocomplete>
                </v-col>
              </v-row>


                <v-row v-if="displayEURselector">
                  <v-col cols="12" sm="2" md="2" xs="2" lg="2" xl="2" class="mt-2">
                   EUR
                  </v-col>
                  <v-col cols="12" sm="1" md="1" xs="1" lg="1" xl="1" class="pa-0 mt-0" >
                    <v-checkbox v-model="showEUR"></v-checkbox>
                  </v-col>
                </v-row>

                <v-layout row >
                  <v-flex xs3 class="py-4 px-2">
                    
                  </v-flex>
                  <v-flex xs3 class="py-4 px-2">
   
                  </v-flex>
                </v-layout>

              <v-layout row wrap>
                <v-flex xs3>
 
                </v-flex>
                <v-flex xs2>
  
                </v-flex>
                <v-flex xs1></v-flex>
                <v-flex xs2>

                </v-flex>
              </v-layout>
   

              <v-layout row wrap>
                <v-flex xs3>

                </v-flex>

                <v-flex xs9>
 
                </v-flex>

              </v-layout>


          </v-card-text>
          <v-divider class="mt-1 mb-3"></v-divider>
          <v-card-actions>


          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 sm12 md4 lg4 ex4>
        <v-row v-if="showCard1 || showCard2 || showCard3 || showCard4 || showCard5 || showCard6">
          <v-col cols="12" sm="12" md="12" xs="12" lg="12" xl="12">
            <MainLedgerLatest />
          </v-col>
        </v-row>
      </v-flex>
    </v-row>
    <v-row>
      <v-flex xs12 sm12>
        <div v-if="!finishYear">
          <v-btn
          text
          color="green"
          :disabled="!formIsValid"
          @click="confirmFinishYear()">
          {{finishYearDesc}}
         </v-btn>
        </div>
        <div v-if="finishYear">
          <v-btn
            text
            color="green"
            :disabled="!formIsValid"
            @click="register(10)">
            {{lang.prepareBuyerSupplier}}
          </v-btn>
          <v-btn
            text
            color="green"
            :disabled="!formIsValid"
            @click="closeBuyerSupplier()">
            {{lang.closeBuyerSupplier}}
          </v-btn>
            <v-btn
            text
            color="green"
            :disabled="!formIsValid"
            @click="register(7)">
            {{lang.FinishBalanceSheet}}
          </v-btn>
          <v-btn
            text
            color="green"
            :disabled="!formIsValid"
            @click="justalert()">
            {{lang.CreateFinish}}
          </v-btn>
          <v-btn
            text
            color="green"
            :disabled="!formIsValid"
            @click="register(8)">
            {{lang.CloseYear}}
          </v-btn>
          <v-btn
            text
            color="green"
            :disabled="!formIsValid"
            @click="closeOptions()">
            {{langC.Back}}
         </v-btn>
        </div>


         <v-btn
          class="primary mr-2"
          :disabled="!formIsValid"
          @click="register(1)">
          {{langC.BalanceSheet}}
         </v-btn>

          <v-btn
            class="primary mr-2"
            :disabled="!formIsValid"
            @click="register(2)">
            Kartica
          </v-btn>

          <v-btn
            class="primary mr-2"
            :disabled="!formIsValid"
            @click="register(3)">
            {{langC.PartnerOpen}}
          </v-btn>

        <v-btn
          class="primary mr-2"
          :disabled="!formIsValid"
          @click="register(5)">
          {{langC.AllPartnerOpen}}
        </v-btn>

        <v-btn
          class="primary mr-2"
          :disabled="!formIsValid"
          @click="register(6)">
          {{lang.AllPartnerCard}}
        </v-btn>

        <v-btn
          class="primary mr-2"
          :disabled="!formIsValid"
          @click="register(9)">
          {{lang.AllPartnersBankList}}
        </v-btn>

        <v-btn
          class="primary mr-2"
          :disabled="!formIsValid"
          @click="register(4)">
          {{langC.Analysis}}
        </v-btn>
      </v-flex>
    </v-row>
    <v-progress-linear v-if="saving"
      indeterminate
      color="blue"
      class="mt-5"
    ></v-progress-linear>

    <v-row v-if="showCard1">
      <MainLedgerCard1 :items="items" :psItems="psItems" :account4Loc="account4Loc" :head="mainledgerAna" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard2">
      <MainLedgerCard2 :items="items" :head="mainledgerAna" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard3">
      <MainLedgerCard3 :items="items" :head="mainledgerAna" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard4">
      <MainLedgerCard4 :items="items4" :head="mainledgerAna" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard5">
      <MainLedgerCard5 :items="items" :head="mainledgerAna" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard6">
      <MainLedgerCard4 :items="items6" :head="mainledgerAna" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard9">
      <MainLedgerCard9 :items="items9" :head="mainledgerAna" :totals="totalsNine" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard10">
      <MainLedgerCard10 :items="newItems" :head="mainledgerAna" :fTotal1="fTotal1" :fTotal2="fTotal2" :fTotal5="fTotal5" :showEUR="showEUR"/>
    </v-row>
    <v-row v-if="showCard1">
    
    </v-row>
    <v-snackbar
      v-model="snackbar"
      :timeout="timeout"
      bottom
      :color="color"
      >
      {{ text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import dayjs from 'dayjs'
import Account4Service from '@/services/Account4Service'
import MainLedgerService from '@/services/MainLedgerService'
import MainLedgerDetailService from '@/services/MainLedgerDetailService'
import MainLedgerProjectTypeService from '@/services/MainLedgerProjectTypeService'
import MainLedgerCard1 from './MainLedgerCard1'
import MainLedgerCard2 from './MainLedgerCard2'
import MainLedgerCard3 from './MainLedgerCard3'
import MainLedgerCard4 from './MainLedgerCard4'
import MainLedgerCard5 from './MainLedgerCard5'
import MainLedgerCard9 from './MainLedgerCard9'
import MainLedgerCard10 from './MainLedgerCard10'
import MainLedgerLatest from './MainLedgerLatest'
import ledgerEN from './MainLedgerDescEN'
import ledgerHR from './MainLedgerDescHR'
import commEn from "@/language/commonEN"
import commHr from "@/language/commonHR"
import dateFormat from '@/snippets/dateFormat'
import { defMainLedger, defNew, defMainLedgerDetail, defMainLedgerDetailFill } from './mainledgerHelper'
import { round, parseNum, dynamicSort, doZeros } from '@/snippets/allSnippets'
import { testLastUpdate } from '@/services/TestLastUpdate'

export default {
  name: 'mainledgeranalysis',
  $_veeValidate: {
    validator: 'new'
  },
  data () {
    return {
      snackbar: false,
      text: '',
      timeout: 2000,
      color: 'green',
      menu: false,
      menu2: false,
      menu3: false,
      menu4: false,
      dateFormatted: null,
      dateFormatted2: null,
      modal: false,
      modal1: false,
      modal2: false,
      modal3: false,
      modal4: false,
      dialog1: false,
      dialog2: false,
      dialog3: false,
      dialog4: false,
      date1: null,
      date2: null,
      msg: '',
      valid: true,
      startDate: null,
      endDate: null,
      mainledgerAna: {
        CompanyId: 0,
        CompanyNameDatabase: '',
        BusinessYear: 0,
        UserId: 0,
        email: '',
        First: '',
        Last: '',
        fromDate: null,
        toDate: null,
        fromAccount: '',
        toAccount: ''
      },
      comp: {},
      error: null,
      localError: false,
      size: 'sm',
      account4Loc: [],
      account4LocFrom: [],
      account4LocTo: [],
      partners: [],
      items: [],
      items4: [],
      items6: [],
      items9: [],
      psItems: [],
      CompanyId: 0,
      selectPa: {},
      selectA4: {},
      selectA4from: {},
      selectA4to: {},
      selectUs3: {},
      mainQuery: {
        CompanyId: 1
      },
      havePath: '',
      PrintDate: null,
      lang: {},
      langC: {},
      saving: false,
      showCard1: false,
      showCard2: false,
      showCard3: false,
      showCard4: false,
      showCard5: false,
      showCard6: false,
      showCard9: false,
      showCard10: false,
      bookType: [],
      bookTypeSelected: {
      },
      bookTypeSelectedName: 'TEM',
      sameAsFrom: false,
      fTotal1: 0,
      fTotal2: 0,
      fTotal3: 0,
      fTotal4: 0,
      fTotal5: 0,
      ffTotal1: 0,
      ffTotal2: 0,
      ffTotal3: 0,
      ffTotal4: 0,
      ffTotal5: 0,
      projectTypes: [],
      selectPT: {},
      newItem: {},
      newItems: [],
      exRate: this.$store.getters.NeedDefExRate,
      exRate2: 1,
      totalsNine: {},
      finishYear: false,
      finishYearDesc: '',
      months: [1,2,3,4,5,6,7,8,9,10,11,12],
      selectedMonth: null,
      showEUR: true,
      displayEURselector: false,
      pItems: [],
      allItems: [],
      fullAdmin: false
    }
  },
  components: {
    MainLedgerCard1,
    MainLedgerCard2,
    MainLedgerCard3,
    MainLedgerCard4,
    MainLedgerCard5,
    MainLedgerCard9,
    MainLedgerCard10,
    MainLedgerLatest
  },
  async mounted () {
    if (this.$store.state.language === "EN") {
      this.lang = ledgerEN
      this.langC = commEn;
    }
    if (this.$store.state.language === "HR") {
      this.lang = ledgerHR
      this.langC = commHr;
    }
   
    // need five arrays with partners, employes, tasktype, taskdepartment, users
    if (this.$store.state.companyid) {
      this.CompanyId = this.$store.state.companyid
      this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
    }

    this.showEUR = this.$store.state.businessYear && this.$store.state.businessYear <= 2022 ? true : false
    
    this.comp = this.$store.state.companyActiv
    if (this.CompanyId > 0) {
      //await checkPartners(0)
      await testLastUpdate()
      if (this.$store.state.partnerStore) {
        this.partners = this.$store.state.partnerStore
      }
      //this.partners = (await PartnerService.index()).data.partners
      this.account4Loc = (await Account4Service.index(this.mainledgerAna)).data.account4s
      this.account4LocFrom = this.account4Loc
      this.account4LocTo = (await Account4Service.index2(this.mainledgerAna)).data.account4s
    }
    if (this.$store.state.user.id) {
      this.mainledgerAna.UserId = this.$store.state.user.id
    }
    if (this.$store.state.user.email) {
      this.mainledgerAna.email = this.$store.state.user.email
    }
    if (this.$store.state.user.First) {
      this.mainledgerAna.First = this.$store.state.user.First
    }
    if (this.$store.state.user.Last) {
      this.mainledgerAna.Last = this.$store.state.user.Last
    }
    this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
    this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
    this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
    this.displayEURselector = this.$store.state.businessYear <= 2022 ? true : false
    this.finishYearDesc = this.lang.FinishYear + this.mainledgerAna.BusinessYear
    if (this.account4Loc) {
      this.selectA4 = this.account4Loc[0]
    }
    if (this.account4LocTo) {
      this.selectA4to = this.account4LocTo[0]
    }
    let locDate1 = new Date()
    this.PrintDate = dayjs().format('DD.MM.YYYY, H:mm')
    
    this.startDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
    this.endDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
    locDate1.getDate()
    locDate1.getMonth() + 1
    locDate1.getFullYear()
    this.bookType = this.$store.state.booktype ? this.$store.state.booktype : []
    this.projectTypes = (await MainLedgerProjectTypeService.index(this.mainledgerAna)).data.mainledgerprojecttype
  },
  computed: {
    formIsValid () {
      return this.startDate !== null &&
        this.endDate !== null &&
        this.mainledgerAna.CompanyId !== ''
    },
    formatDate1() {
      return this.startDate
        ? dateFormat(this.startDate)
        : "";
    },
    formatDate2() {
      return this.endDate
        ? dateFormat(this.endDate)
        : "";
    },
  },
  methods: {
    formatDate (date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    localDate(dateToFormat) {
       return dateFormat(dateToFormat)
    },
    parseDate (date) {
      if (!date) {
        return null
      }
      const [day, month, year] = date.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    checkAcc (choosedAcc) {
      if (choosedAcc) {
        const result = this.account4Loc.filter(element => element.account4 === choosedAcc)
        if (result[0]) {
          const res = result[0]
          return res.account4Description
        } else {
          return null
        }
      }
    },
    justalert() {
      alert('Proknjižite ručno završnu temeljnicu i vratite se ovdje na korak 3.')
    },
    confirmFinishYear() {
      const msgAmo = ('Sigurni ste da želite zatvaranje poslovne godine  ' + (this.$store.state.businessYear ? this.$store.state.businessYear : 0) + ' ?') 
      if (confirm(msgAmo)) {
        this.finishYear = true
      }
    },
    closeOptions() {
      this.finishYear = false
    },
    getList  (item, queryText, itemText) {
      return itemText.toLocaleLowerCase().startsWith(queryText.toLocaleLowerCase())
    },
    choosedProjectType (choosedPT) {
      if (choosedPT) {
        const result = this.projectTypes.filter(element => element.id === choosedPT.id)
        if (result[0]) {
          const res = result[0]
          this.mainledgerAna.TypeID = res.id
          this.mainledgerAna.TypeName = res.TypeName
          this.selectPT = res
        } else {
          this.text = this.lang.MainLedgerProjectTypeEmpty
          this.color = 'red'
          this.snackbar = true
        }
      } else {
        this.mainledgerAna.TypeID = null
        this.mainledgerAna.TypeName = null
      }     
    },
    print () {
      // opens the "print dialog" of your browser to print the element
      // var bdhtml = window.document.body.innerHTML
      // window.document.body.innerHTML = document.getElementById('printArea').innerHTML
      // window.print()
      // window.document.body.innerHTML = bdhtml
      this.$htmlToPaper('printArea')
    },
    clearAllSets() {
      Object.keys(this.mainledgerAna).forEach(k => delete this.mainledgerAna[k])
      this.localError = false
      this.showCard1 = false
      this.showCard2 = false
      this.showCard3 = false
      this.showCard4 = false
      this.showCard5 = false
      this.showCard6 = false
      this.showCard9 = false
      this.showCard10 = false
      if (this.$store.state.user.id) {
        this.mainledgerAna.UserId = this.$store.state.user.id
      }
      if (this.$store.state.user.email) {
        this.mainledgerAna.email = this.$store.state.user.email
      }
      if (this.$store.state.user.First) {
        this.mainledgerAna.First = this.$store.state.user.First
      }
      if (this.$store.state.user.Last) {
        this.mainledgerAna.Last = this.$store.state.user.Last
      }
      this.mainledgerAna.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
      this.mainledgerAna.CompanyNameDatabase = this.$store.state.companyName ? this.$store.state.companyName : null
      this.mainledgerAna.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2022
    },
    async register (type, subtype) {
      try {
        //clear all
        console.log('registriram', type, subtype)
        this.clearAllSets()
         this.error = null

        this.items.splice(0,this.items.length)
        this.psItems.splice(0,this.psItems.length)
        if (this.mainledgerAna.partnerId) {
          delete this.mainledgerAna.partnerId
        }
        if (this.mainledgerAna.partnerVATID) {
          delete this.mainledgerAna.partnerVATID
        }
        if (this.mainledgerAna.bookTypeSelected) {
          delete this.mainledgerAna.bookTypeSelected
        }
        if (this.mainledgerAna.subtype) {
          delete this.mainledgerAna.subtype
        }
 
        // new initalization
        this.mainledgerAna.fromAccount = this.selectA4.account4 ? this.selectA4.account4 : null
        this.mainledgerAna.toAccount = this.selectA4to.account4 ? this.selectA4to.account4 : null
        this.mainledgerAna.type = type
        this.mainledgerAna.subtype = subtype ? subtype : null

        // check account4 for buyers/suppliers
        if (type === 2 || type === 3 || type === 5 || type === 6 || type === 9 || type === 10) {
          const fromA = this.mainledgerAna.fromAccount.substring(0,2)
          const toA = this.mainledgerAna.toAccount.substring(0,2)
          if ((fromA === '12' || fromA === '22' || fromA === '24' || fromA === '16') && (toA === '12' || toA === '22' || toA === '24' || toA === '16')) {
            if (fromA === '12' && toA !== '12') {
              this.text = 'Error! ' + 'Morate odabrati i zavrsni konto kupca'
              this.color = 'pink'
              this.snackbar = true
              return
            }
            if (fromA === '22' && toA !== '22') {
              this.text = 'Error! ' + 'Morate odabrati i zavrsni konto dobavljaca'
              this.color = 'pink'
              this.snackbar = true
              return
            }
             if (fromA === '24' && toA !== '24') {
              this.text = 'Error! ' + 'Morate odabrati i zavrsni konto dobavljaca'
              this.color = 'pink'
              this.snackbar = true
              return
            }
          } else {
            this.text = 'Error! ' + 'Morate odabrati saldakontni konto za ovu vrstu izvjestaja!'
            this.color = 'red'
            this.snackbar = true
            return
          }
        }        
        // do analysis from database
 
        if (this.selectPT && Object.keys(this.selectPT).length > 0) {
          this.mainledgerAna.TypeID = this.selectPT.id
          this.mainledgerAna.TypeName = this.selectPT.TypeName
        }
        // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open, 6 all cards (same as 5 but other type)
        console.log('ML type', type)

        this.mainledgerAna.type = type
        if (type === 5) {
          this.mainledgerAna.subtype = 5
        }
        if (type === 6) {
          this.mainledgerAna.type = 5
          this.mainledgerAna.subtype = 6
        }
        if (type === 7 || type === 8) {
          this.mainledgerAna.type = 1
        }
        if (type === 9) {
          this.mainledgerAna.type = 6
        }
        if (type === 10) {
          this.mainledgerAna.type = 5
        }

        if (this.selectPa && this.selectPa.id) {
          this.mainledgerAna.partnerId = this.selectPa.id
          this.mainledgerAna.partnerVATID = this.selectPa.partnerVATID
        }
        if (type ===2 || type === 3) {
          if (!this.mainledgerAna.partnerId || !this.mainledgerAna.partnerVATID) {
            alert('Greska!! Nije odabran partner!! Error! Partner is not selected')
            return
          }
        }
        if (this.bookTypeSelected && this.bookTypeSelected.value) {
          this.mainledgerAna.bookTypeSelected = parseInt(this.bookTypeSelected.value)
        }
        
        this.mainledgerAna.docFromDate = this.startDate ? dayjs(this.startDate).format('YYYY-MM-DD') : null
        this.mainledgerAna.docToDate = this.endDate ? dayjs(this.endDate).format('YYYY-MM-DD') : null
        this.$store.dispatch('setSearchState', this.mainledgerAna)
        console.log('this.mainledgerAna', this.mainledgerAna)

        this.saving = true
        await MainLedgerDetailService.card(this.mainledgerAna)
        .then(async(res) => {
        
          this.items = res.data.documents ? res.data.documents : []
          //console.log('da vidim sto mi server vrati this.items', this.items)
          
          if (this.items) {
 
            if (type === 1) {
              // tak start numbers
              if (this.mainledgerAna.bookTypeSelected) {
                delete this.mainledgerAna.bookTypeSelected
              }
              if (this.mainledgerAna.subtype) {
                delete this.mainledgerAna.subtype
              }
              this.mainledgerAna.bookTypeSelected = 21

              const resss = await MainLedgerDetailService.card(this.mainledgerAna)
              if (resss && resss.data && resss.data.documents) {
                this.psItems = resss.data.documents
              }
              this.itemsFour()
            }
            if (type === 4) {
              this.itemsFour()
            }
            // if (type === 6) {
            //   this.itemsSix()
            // }
            if (type === 7) {
              this.closeYear()
            }
            if (type === 8) {
              this.copyFinishedYear()
            }
            if (type === 9) {
              this.itemsNine()
            }

            if (type === 10) {
              await this.itemsTen()
            }

            this.showCard1 = type === 1 ? true : false
            this.showCard2 = type === 2 ? true : false
            this.showCard3 = type === 3 ? true : false
            this.showCard4 = type === 4 ? true : false
            if (type === 5) {
               this.showCard5 = true 
            }
            if (type === 6) {
               this.showCard5 = true 
            }
            this.showCard9 = type === 9 ? true : false
            // samo za opzstat --> treba ponvno napraviti opz stat da ih kreira uz opciju print svi otvoreno
           // this.showCard10 = type === 10 ? true : false

          }
        })
        .catch(err => {
           this.error = 'Error  ' + err
        })
        this.saving = false
       
      } catch (error) {
        this.error = 'Greska u konekciji na bazu' + error
      }
    },
    close () {
      this.show = false
    },
    async findPartner(pVATID){
        if (!pVATID) {
          return {}
        }

        let tempPartner = {}
        tempPartner = this.partners.find(part => (part.partnerVATID === pVATID))
        if (tempPartner) {
          return tempPartner
        }
        // if don't have partner in table partners, try to find in MainLedger some data
        if (!tempPartner) {
          const newMainQuery = {}
          newMainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
          newMainQuery.type = 7
          newMainQuery.partnerVATID = pVATID
          await MainLedgerDetailService.card(newMainQuery)
          .then(res => {
            // console.log('respond', res.data)
            if (res.data && res.data.documents) {
              tempPartner.partnerName = res.data.documents.partnerName ? res.data.documents.partnerName : {}
              tempPartner.partnerVATID = res.data.documents.partnerVATID ? res.data.documents.partnerVATID : {}
              return tempPartner
            } else {
              tempPartner.partnerVATID = pVATID
              return tempPartner
            }
          })
          .catch(err => {
            tempPartner.partnerVATID = pVATID
            console.log('err', err)
            return tempPartner
          })
        }
    },
    async subItems() {

    },
    // not in use
    async itemsTenxx() {
      await this.itemsTen1()
      await this.itemsTen2()
     // await this.itemsTen3()
    },
    // not in use
    async itemsTen3() {
      if (this.newItems) {
        this.newItems.map(async(itemC) => {
          if (!itemC.partner && itemC.partnerVATID) {
            const newMainQuery = {}
            const tempPartner = {}
            newMainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 0
            newMainQuery.type = 7
            newMainQuery.partnerVATID = itemC.partnerVATID
            await MainLedgerDetailService.card(newMainQuery)
            .then(res => {

              if (res.data && res.data.documents) {
                tempPartner.partnerName = res.data.documents.partnerName ? res.data.documents.partnerName : {}
                tempPartner.partnerVATID = res.data.documents.partnerVATID ? res.data.documents.partnerVATID : {}
                const item = {...itemC}
                item.partner = tempPartner ? tempPartner : {}
                this.newItems[this.newItems.findIndex(el => el.partnerVATID === itemC.partnerVATID)] = item
              }
            })
            .catch(err => {
              tempPartner.partnerVATID = itemC.partnerVATID
              const item = {...itemC}
              item.partner = tempPartner ? tempPartner : {}
              this.newItems[this.newItems.findIndex(el => el.partnerVATID === itemC.partnerVATID)] = item
              console.log('err', err)
            })
            
        }})
      }
    },
    // not in use
    async itemsTen2(forPartner) {   
      const withPartner = []      
      if (forPartner) {
        forPartner.map(async(itemB) => {
          const newTempPartner = itemB.partnerVATID ? await this.partners.find(part => (part.partnerVATID === itemB.partnerVATID)) : {}
          const item = {...itemB}
          item.partnerName = newTempPartner && newTempPartner.partnerName ? newTempPartner.partnerName.toLocaleUpperCase() : null
          withPartner.push(item)
        })
        
      }
      return withPartner
    },
    async itemTenX2() {
      if (this.mainledgerAna && this.mainledgerAna.partnerId) {
        delete this.mainledgerAna.partnerId
      }
      this.mainledgerAna.type = 4
     
      await MainLedgerDetailService.card(this.mainledgerAna)
      .then((res) => {
        if (res && res.data && res.data.documents && res.data.documents.length > 0) {
          this.allItems = [...res.data.documents]
          
        }
      })
      .catch(err => {
        console.log('err ', err)
      })

    },
    async itemsTen() {
      if (this.allItems && this.allItems.length > 0) {
        this.allItems.length = 0
      }
      if (this.pItems && this.pItems.length > 0) {
        this.pItems.length = 0
      }
      await this.itemTenX2()

      let itLine = 0
      this.items.map((item) => {
        const newDebit = round((parseFloat(item.totalDebit) / this.exRate), 2)
        const newCredit = round((parseFloat(item.totalCredit) / this.exRate), 2)

        const diff = newDebit - newCredit
        this.partner = {}
        if (diff !== 0) {
          // reduce det items to be shure what invoices are open and what are closed
          // only open push to pItems

          itLine += 1
          this.Total1 += newDebit
          this.Total2 += newCredit
          const par = this.partners.find(part => (part.partnerVATID === item.partnerVATID))

          const object2 = {
            itLine,
            tDebit: newDebit,
            tCredit: newCredit,
            CreditDebit: diff,
            partner: (par ? par : {}),
            partnerName: (par && par.partnerName ? par.partnerName  : '')
          }

          const object3 = {...item, ...object2}
          this.pItems.push(object3)
        }
      })
      let side = 2
      // buyers
      if (String(this.mainledgerAna.fromAccount).substring(0, 2) === '12') {
        side = 1
      }
  
      const newCards = []
      
      this.newItems.length = 0
      this.fTotal1 = 0
      this.fTotal2 = 0
      this.fTotal3 = 0
      this.fTotal4 = 0
      this.fTotal5 = 0

      // // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
      // this.mainledgerAna.type = 3

      if (this.pItems) {

        this.pItems.map((itemA) => {

          const diff = round(parseFloat(itemA.totalDebit),2) - round(parseFloat(itemA.totalCredit), 2)
          if (diff !== 0 && itemA.partnerVATID && itemA.partnerVATID.length > 0) {
            let haveCard = false
            const tempItems = []

            const subItems = this.allItems.filter(f => f.partnerVATID === itemA.partnerVATID)

            if (subItems && subItems.length > 0) {
                subItems.map(adY => {
                  const tIndx = subItems.indexOf(adY)
                  if (side === 1) {
                    subItems[tIndx].inOutNr = doZeros(adY.invoiceOutNumber, adY.BusinessYearInvoice)
                    subItems[tIndx].Connection = (adY.invoiceOutNumber)+ '/' + adY.BusinessYearInvoice
                  }
                  if (side === 2) {
                    subItems[tIndx].inInNr = doZeros(adY.invoiceInNumber, adY.BusinessYearInvoice)
                    subItems[tIndx].Connection = (adY.invoiceInNumber)+ '/' + adY.BusinessYearInvoice
                  }
                })
                
                let subLine = 0
                let subTotal1 = 0
                let subTotal2 = 0
                let resDebit = []
                let resCredit = []

                let result = []

                if (side === 1) {
                  resDebit = Array.from(subItems.reduce(
                         (m, {inOutNr, Debit}) => m.set(inOutNr, (m.get(inOutNr) || 0) + Debit), new Map
                       ), ([inOutNr, Debit]) => ({inOutNr, Debit}));

                  resCredit = Array.from(subItems.reduce(
                         (m, {inOutNr, Credit}) => m.set(inOutNr, (m.get(inOutNr) || 0) + Credit), new Map
                       ), ([inOutNr, Credit]) => ({inOutNr, Credit}));
                }
                if (side === 2) {
                  resDebit = Array.from(subItems.reduce(
                         (m, {inInNr, Debit}) => m.set(inInNr, (m.get(inInNr) || 0) + Debit), new Map
                       ), ([inInNr, Debit]) => ({inInNr, Debit}));

                  resCredit = Array.from(subItems.reduce(
                         (m, {inInNr, Credit}) => m.set(inInNr, (m.get(inInNr) || 0) + Credit), new Map
                       ), ([inInNr, Credit]) => ({inInNr, Credit}));

                }

                let cond = ''
                subItems.map(sitem => {
                  if (side === 1 ? sitem.inOutNr !== cond :  sitem.inInNr !== cond) {
                    side === 1 ? cond = sitem.inOutNr :  cond = sitem.inInNr
                  
                    let dInTotal = {}
                    let cInTotal = {}
                    if (side === 1) {
                      dInTotal = resDebit && resDebit.length > 0 ? resDebit.find(itm => itm.inOutNr === sitem.inOutNr) : 0
                      cInTotal = resCredit && resCredit.length > 0 ? resCredit.find(itm => itm.inOutNr === sitem.inOutNr) : 0
                    }
                    if (side === 2) {
                      dInTotal = resDebit && resDebit.length > 0 ? resDebit.find(itm => itm.inInNr === sitem.inInNr) : 0
                      cInTotal = resCredit && resCredit.length > 0 ? resCredit.find(itm => itm.inInNr === sitem.inInNr) : 0
                    }

                    if (round((dInTotal && dInTotal.Debit ? round(dInTotal.Debit,2) : 0) - (cInTotal && cInTotal.Credit ? round(cInTotal.Credit,2): 0),2) !== 0) {
                      subLine += 1
                      subTotal1 += round(dInTotal.Debit, 2)
                      subTotal2 += round(cInTotal.Credit, 2)
                      this.fTotal1 += round(dInTotal.Debit, 2)
                      this.fTotal2 += round(cInTotal.Credit, 2)
                      
                      let itInvoice
                      if (sitem.BookName.substring(0,3) === 'IRA' || sitem.BookName.substring(0,3) === 'URA') {
                        itInvoice = parseInt(sitem.invoiceInNumber) > 0 ? parseInt(sitem.invoiceInNumber) : parseInt(sitem.invoiceOutNumber)
                      }
                      if (sitem.BookName.substring(0,2) === 'PS') {
                        itInvoice = parseInt(sitem.invoiceInNumber) > 0 ? parseInt(sitem.invoiceInNumber) : parseInt(sitem.invoiceOutNumber)
                      }
                      if (sitem.BookName.substring(0,3) === 'TEM') {
                        itInvoice = parseInt(sitem.BookID)
                      }
                      if (sitem.BookName.substring(0,2) === 'BS') {
                        itInvoice = parseInt(sitem.BookID)
                      }

                      const bookName = sitem.BookName === 'BS' ? 'Izv.' : sitem.BookName
                      const object2 = {
                        itBookName: subLine + '. ' + bookName,
                        Debit: round(dInTotal.Debit, 2),
                        Credit: round(cInTotal.Credit, 2),
                        CreditDebit: round(dInTotal.Debit, 2) - round(cInTotal.Credit, 2),
                        itInvoice: itInvoice,
                        subLine: subLine
                      }

                      haveCard = true
                      const object3 = {...sitem, ...object2}
                      tempItems.push(object3)
                    }
                  }
                })

                if (haveCard) {

                  let subTotal5 = subTotal1 - subTotal2
                  itLine += 1
                  const card = {}
                  card.id = itLine
                  card.partnerVATID = itemA.partnerVATID
                  const havePartner = itemA.partnerVATID ? this.partners.find(part => (part.partnerVATID === itemA.partnerVATID)) : {}
                  card.partnerName = havePartner && havePartner.partnerName ? havePartner.partnerName : ''
                  card.partner = havePartner
                  card.detItems = tempItems

                  card.Total1 = subTotal1
                  card.Total2 = subTotal2
                  card.Total5 = subTotal5
                  this.newItems.push(card)
                }
          
            }
          }
          
        })

        alert('Saldakonti datoteka spremna. Možete na korak 2.')
      }

    },
    async itemsTenOld() {

      let itLine = 0
      const newArr = []
      this.newItems = [...newArr]
      this.fTotal1 = 0
      this.fTotal2 = 0
      this.fTotal3 = 0
      this.fTotal4 = 0
      this.fTotal5 = 0
      const notSorted = await this.itemsTen2(this.items)
      notSorted.sort((a, b) => {
        if (a.partnerName > b.partnerName) {
          return 1;
        }
        if (a.partnerName < b.partnerName) {
          return -1;
        }
        return 0;
      });

     // console.log('2 notSorted', notSorted)

      // type 1 = balance sheet, 2 = partner card,, 3 = partner open, 4 = analysis, 5= all partners open
      this.mainledgerAna.type = 3
      if (notSorted) {
        
        //console.log('items SORTED with partner ', await this.itemsTen2(notSorted))
        notSorted.map(async(itemA) => {
          const diff = round(parseFloat(itemA.totalDebit),2) - round(parseFloat(itemA.totalCredit), 2)
          if (diff !== 0 && itemA.partnerVATID && itemA.partnerVATID.length > 0) {
            if (itemA.partnerVATID) {
              this.mainledgerAna.partnerVATID = itemA.partnerVATID
            }
           this.fTotal1 += round(parseNum(itemA.totalDebit), 2)
           this.fTotal2 += round(parseNum(itemA.totalCredit), 2)
           
            await MainLedgerDetailService.card(this.mainledgerAna)
            .then(async(res) => {
              if (res && res.data && res.data.documents && res.data.documents.length > 0) {
                 const subItems = res.data.documents

                 let tempItems = []
                 let subTotal1 = 0
                 let subTotal2 = 0
                 let subTotal5 = 0
                 let subLine = 0
                 if (subItems) {
                  subItems.sort((a,b) => (a.BookingDate > b.BookingDate) ? 1 : ((b.BookingDate > a.BookingDate) ? -1 : 0))
                  subItems.map(subitem => {
                    subLine += 1
                    const pDebit = (subitem.totalDebit ? parseFloat(subitem.totalDebit) : subitem.Debit)
                    const pCredit = (subitem.totalCredit ? parseFloat(subitem.totalCredit) : subitem.Credit)
                    subTotal1 += pDebit
                    subTotal2 += pCredit
                    // partnerVATID = subitem.partnerVATID ? subitem.partnerVATID : null
                    let itInvoice
                    if (subitem.BookName.substring(0,3) === 'IRA' || subitem.BookName.substring(0,3) === 'URA') {
                      itInvoice = parseInt(subitem.invoiceInNumber) > 0 ? parseInt(subitem.invoiceInNumber) : parseInt(subitem.invoiceOutNumber)
                    }
                    if (subitem.BookName.substring(0,3) === 'TEM') {
                      itInvoice = parseInt(subitem.BookID)
                    }
                    if (subitem.BookName.substring(0,2) === 'BS') {
                      itInvoice = parseInt(subitem.BookID)
                    }

                    const bookName = subitem.BookName !== 'BS' ? subitem.BookName : 'Izv.'
                    // subTotal1 += round(parseNum(subitem.totalDebit), 2)
                    // subTotal2 += round(parseNum(subitem.totalCredit), 2)

                    // const itInvoice = subitem.invoiceInNumber && isNaN(parseInt(subitem.invoiceInNumber)) === false ? parseInt(subitem.invoiceInNumber) : (subitem.invoiceOutNumber && isNaN(parseInt(subitem.invoiceOutNumber)) === false ? parseInt(subitem.invoiceOutNumber) : '')
                    // const bookName = subitem.BookName !== 'BS' ? subitem.BookName : 'Izv.' + parseInt(subitem.BookID)
                    const object2 = {
                      itBookName: subLine + '. ' + bookName,
                      CreditDebit: subTotal1 - subTotal2,
                      itInvoice: itInvoice,
                      subLine: subLine,
                    }
                    const object3 = {...subitem, ...object2}
                    tempItems.push(object3)
                  })
                }
                
                subTotal5 = subTotal1 - subTotal2
                itLine += 1
                const card = {}
                card.id = itLine
                card.partnerVATID = itemA.partnerVATID
                const havePartner = itemA.partnerVATID ? await this.partners.find(part => (part.partnerVATID === itemA.partnerVATID)) : {}
                card.partnerName = havePartner && havePartner.partnerName ? havePartner.partnerName : ''
                card.partner = havePartner
                card.detItems = tempItems
                //this.card = {...this.card, ...this.lastOne}
                card.Total1 = subTotal1
                card.Total2 = subTotal2
                card.Total5 = subTotal5
                this.newItems.push(card)
              }
            })
            .catch(err => {
              console.log('Error 2', err)
            })
            
          }
        })
        this.fTotal5 =  round(this.fTotal1 - this.fTotal2 , 2)
        // console.log('Newitems unsorted ', this.newItems)
        // if (this.newItems) {
        //   this.newItems.sort(dynamicSort("partnerName"))
        //   //this.newItems.sort((a,b) => (a.partnerName > b.partnerName) ? 1 : ((b.partnerName > a.partnerName) ? -1 : 0))
        // }
        // console.log('Newitems Sorted ', this.newItems)
        alert('Saldakonti datoteka spremna. Možete na korak 2.')
      }
     
    },
    itemsNine() {

      let itLine = 0
      this.items9 = []

      let Total30 = 0
      let Total60 = 0
      let Total90 = 0
      let Total120 = 0
      let TotalOver = 0
      let ffTotal30 = 0
      let ffTotal60 = 0
      let ffTotal90 = 0
      let ffTotal120 = 0
      let ffTotalOver = 0
      if (this.items) {
        
        this.items.map(item => {
          if (item.length > 0) {
            Total30 = 0
            Total60 = 0
            Total90 = 0
            Total120 = 0
            TotalOver = 0
            itLine += 1
            const Head = {}
            Head.account4 = item[0].account4
            const fullAccount = this.account4Loc.find(account => account.account4 === item[0].account4)
            Head.account4Description = fullAccount ? fullAccount.account4Description : ''
            Head.partnerName = item[0].partnerName
            Head.partnerVATID = item[0].partnerVATID
            Head.line = itLine
            let saveIt = false

            item.map(subitem => {
              const startDate = dayjs(subitem.InvoicePaymentDeadlineDate, 'YYYY-MM-DD').toDate()
              const todayDate = new Date()
              const daysDiff = round((Math.floor(( todayDate - startDate ) / 86400000)), 0)
              const saldo = Math.abs(round(parseNum(subitem.Debit), 2) - round(parseNum(subitem.Credit),2))
  
              if (daysDiff < 30) {
                Total30 += round(saldo, 2)
                ffTotal30 += round(saldo, 2)
              }
              if (daysDiff >= 30 && daysDiff < 60) {
                Total60 += round(saldo, 2)
                ffTotal60 += round(saldo, 2)
              }
              if (daysDiff >= 60 && daysDiff < 90) {
                Total90 += round(saldo, 2)
                ffTotal90 += round(saldo, 2)
              }
              if (daysDiff >= 90 && daysDiff < 120) {
                Total120 += round(saldo, 2)
                ffTotal120 += round(saldo, 2)
              }
              if (daysDiff > 120) {
                TotalOver += round(saldo, 2)
                ffTotalOver += round(saldo, 2)
              }
              if (daysDiff > 0) {
                saveIt = true
              }
            })
            Head.Total30 = round(Total30, 2)
            Head.Total60 = round(Total60, 2)
            Head.Total90 = round(Total90, 2)
            Head.Total120 = round(Total120, 2)
            Head.TotalOver = round(TotalOver, 2)
             
            if (saveIt) {
              this.items9.push(Head)
            }
          }      
        })
        this.totalsNine = {}
        this.totalsNine.Total30 = ffTotal30
        this.totalsNine.Total60 = ffTotal60
        this.totalsNine.Total90 = ffTotal90
        this.totalsNine.Total120 = ffTotal120
        this.totalsNine.TotalOver = ffTotalOver

      }      
    },
    itemsSix() {

      const objHead = {}
      objHead.Head = true
      objHead.Nr = 'Rb.'
      objHead.Type = 'Vrsta'
      objHead.Date = 'Datum'
      objHead.Debit = 'Duguje'
      objHead.Credit = 'Potrazuje'
      objHead.DebitFor = 'Dug.Ino'
      objHead.CreditFor = 'Pot.Ino'
      objHead.Saldo = 'Saldo'
      objHead.HomeCurrency = 'Kn'
      objHead.ForeignCurrency = 'EUR'
      objHead.Description = 'Opis'
      objHead.partnerName = 'Partner'
      objHead.partnerVATID = 'OIB'
      objHead.invoiceInNumber = 'URA broj'
      objHead.invoiceOutNumber = 'IRA broj'
      objHead.BusinessYear = 'Posl.god.'
      objHead.account1 = 'Klasa'
      objHead.account2 = 'Sin'
      objHead.account3 = 'Sin'
      objHead.account4 = 'Konto'
      let itLine = 0
      this.items6 = []

      let Total1 = 0
      let Total2 = 0
      let Total3 = 0
      let Total4 = 0
      let Total5 = 0
      let cardNr = 0
      this.ffTotal1 = 0
      this.ffTotal2 = 0
      this.ffTotal3 = 0
      this.ffTotal4 = 0
      this.ffTotal5 = 0
      if (this.items) {
        
        this.items.map(item => {
          if (item.length > 0) {
            itLine = 0
            const Head = {...objHead}
            Head.account4 = item[0].account4
            const fullAccount = this.account4Loc.find(account => account.account4 === item[0].account4)
            Head.account4Description = fullAccount ? fullAccount.account4Description : ''
            Head.partnerName = item[0].partnerName
            Head.partnerVATID = item[0].partnerVATID
            cardNr += 1
            Head.cardNr = cardNr 
            Total1 = 0
            Total2 = 0
            Total3 = 0
            Total4 = 0
            Total5 = 0
            this.items6.push(Head)
            const partnerItems = []
            item.map(subitem => {
              itLine += 1
              Total1 += parseFloat(subitem.Debit)
              Total2 += parseFloat(subitem.Credit)
              Total3 += parseFloat(subitem.DebitForegin)
              Total4 += parseFloat(subitem.CreditForegin)
              Total5 = Total1 - Total2 
              this.fTotal1 = Total1
              this.fTotal2 = Total2
              this.fTotal3 = Total3
              this.fTotal4 = Total4
              this.fTotal5 = Total5
              this.ffTotal1 += parseFloat(subitem.Debit)
              this.ffTotal2 += parseFloat(subitem.Credit)
              this.ffTotal3 += parseFloat(subitem.DebitForegin)
              this.ffTotal4 += parseFloat(subitem.CreditForegin)
              this.ffTotal5 += Total1 - Total2
              const subObj = {...subitem}
              subObj.Line = itLine
              subObj.cardNr = cardNr 
              subObj.Total5 = Total5
              partnerItems.push(subObj)
            })
            partnerItems.sort(dynamicSort("BookingDate"))
            this.items6.push(...partnerItems)
            const objFooter = {}
            objFooter.Footer = true
            objFooter.Total1 = this.fTotal1
            objFooter.Total2 = this.fTotal2
            objFooter.Total3 = this.fTotal3
            objFooter.Total4 = this.fTotal4
            objFooter.Total5 = this.fTotal5
            this.items6.push(objFooter)
          }       
        })
        const objTFooter = {}
        objTFooter.Footer = true
        objTFooter.Total1 = this.ffTotal1
        objTFooter.Total2 = this.ffTotal2
        objTFooter.Total3 = this.ffTotal3
        objTFooter.Total4 = this.ffTotal4
        objTFooter.Total5 = this.ffTotal1 - this.ffTotal2
        this.items6.push(objTFooter)
      }
    },
    itemsFour() {

      const objHead = {}
      objHead.Head = true
      objHead.Nr = 'Rb.'
      objHead.Type = 'Vrsta'
      objHead.Date = 'Datum'
      objHead.Debit = 'Duguje'
      objHead.Credit = 'Potrazuje'
      objHead.DebitFor = 'Dug.Ino'
      objHead.CreditFor = 'Pot.Ino'
      objHead.Saldo = 'Saldo'
      objHead.HomeCurrency = 'Kn'
      objHead.ForeignCurrency = 'EUR'
      objHead.Description = 'Opis'
      objHead.partnerName = null
      objHead.partnerVATID = 'OIB'
      objHead.invoiceInNumber = 'URA broj'
      objHead.invoiceOutNumber = 'IRA broj'
      objHead.BusinessYear = 'Posl.god.'
      objHead.account1 = 'Klasa'
      objHead.account2 = 'Sin'
      objHead.account3 = 'Sin'
      objHead.account4 = 'Konto'
      let itLine = 0
      this.items4 = []
      let firstTime = true
      let account4 = null
      let Total1 = 0
      let Total2 = 0
      let Total3 = 0
      let Total4 = 0
      let Total5 = 0
      let cardNr = 0
      this.ffTotal1 = 0
      this.ffTotal2 = 0
      this.ffTotal3 = 0
      this.ffTotal4 = 0
      this.ffTotal5 = 0
      if (this.items) {
        this.items.map(item => {
          if (item.account4 && item.account4 !== account4) {
            if (firstTime === false) {
              itLine = 0
              const objFooter = {}
              objFooter.Footer = true
              objFooter.Total1 = Total1
              objFooter.Total2 = Total2
              objFooter.Total3 = Total3
              objFooter.Total4 = Total4
              objFooter.Total5 = Total5
              this.items4.push(objFooter)
              Total1 = 0
              Total2 = 0
              Total3 = 0
              Total4 = 0
              Total5 = 0
              const Head = {...objHead}
              Head.account4 = item.account4
              const fullAccount = this.account4Loc.find(account => account.account4 === item.account4)
              Head.account4Description = fullAccount ? fullAccount.account4Description : ''
              this.items4.push(Head)
              account4 = item.account4
            } else {
              itLine = 0
              const Head = {...objHead}
              Head.account4 = item.account4
              const fullAccount = this.account4Loc.find(account => account.account4 === item.account4)
              Head.account4Description = fullAccount ? fullAccount.account4Description : ''
              cardNr += 1
              Head.cardNr = cardNr 
              this.items4.push(Head)
              account4 = item.account4
              firstTime = false
            }
          }
          itLine += 1
          Total1 += parseFloat(item.Debit)
          Total2 += parseFloat(item.Credit)
          Total3 += parseFloat(item.DebitForegin)
          Total4 += parseFloat(item.CreditForegin)
          Total5 = Total1 - Total2 
          const objItem = {...item}
          objItem.Line = itLine
          objItem.cardNr = cardNr 
          objItem.Total5 = Total5
          this.items4.push(objItem)
          this.fTotal1 = Total1
          this.fTotal2 = Total2
          this.fTotal3 = Total3
          this.fTotal4 = Total4
          this.fTotal5 = Total5
          this.ffTotal1 += parseFloat(item.Debit)
          this.ffTotal2 += parseFloat(item.Credit)
          this.ffTotal3 += parseFloat(item.DebitForegin)
          this.ffTotal4 += parseFloat(item.CreditForegin)
          this.ffTotal5 += Total1 - Total2        
        })
        const objFooter = {}
        objFooter.Footer = true
        objFooter.Total1 = this.fTotal1
        objFooter.Total2 = this.fTotal2
        objFooter.Total3 = this.fTotal3
        objFooter.Total4 = this.fTotal4
        objFooter.Total5 = this.fTotal5
        this.items4.push(objFooter)
        const objTFooter = {}
        objTFooter.Footer = true
        objTFooter.Total1 = this.ffTotal1
        objTFooter.Total2 = this.ffTotal2
        objTFooter.Total3 = this.ffTotal3
        objTFooter.Total4 = this.ffTotal4
        objTFooter.Total5 = this.ffTotal1 - this.ffTotal2
        this.items4.push(objTFooter)
      }
    },
    initNew(llaID) {
      this.newItem = defMainLedgerDetail()
      this.newItem = {...this.newItem, ...defMainLedgerDetailFill()}
      this.newItem.databaseMLID = llaID
      this.newItem.BookTypeID  = this.mainledger.BookTypeID
      this.newItem.MLType  = this.mainledger.MLType
      this.newItem.BookName  = this.mainledger.BookName
      this.newItem.BookID = this.mainledger.BookID
      this.newItem.documentName = this.mainledger.documentName
      this.newItem.documentIdGeneral = this.mainledger.documentIdGeneral
      this.newItem.BookIdGeneral = this.mainledger.BookIdGeneral
      this.newItem.ConnName = this.mainledger.ConnName
      this.newItem.ConnId = this.mainledger.ConnId
      this.newItem.MLFree1 = this.mainledger.MLFree1
    },
    async closeYear() {
        try {
          let errorAccounts = null

          if (!this.checkAcc('7900')) {
            errorAccounts += '7900,'
          }
          if (!this.checkAcc('8030')) {
            errorAccounts += '8030,'
          }
          if (!this.checkAcc('8040')) {
            errorAccounts += '8040,'
          }
          if (!this.checkAcc('8041')) {
            errorAccounts += '8041,'
          }
          if (errorAccounts) {
            alert('Greska! Error! Ne mozete zatvoriti bilancu jer nedostaju konta: ' + errorAccounts)
            return
          }
          // check if exist
          const msgAmo = ('Sigurni ste da želite zatvaranje poslovne godine  ' + (this.$store.state.businessYear ? this.$store.state.businessYear : 0) + '(4 i 7 konta) ?') 
          if (confirm(msgAmo)) {
            this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
            this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
            this.mainQuery.BookTypeID = 29
            this.mainQuery.MLType = 3
            this.mainQuery.ConnId = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
            let llaID = null
            const haveIt = await MainLedgerService.index(this.mainQuery)
            
            if (haveIt && haveIt.data.documents && haveIt.data.documents.length > 0) {
              // have mainledger close, use it
              const head = haveIt.data.documents[0]
              llaID = head.id
              this.mainledger = defMainLedger()
              this.mainledger = {...this.mainledger, ...defNew()}
              this.mainledger.BookTypeID = head.BookTypeID
              this.mainledger.MLType = head.MLType
              this.mainledger.BookName = head.BookName
              this.mainledger.BookID = head.BookID
              this.mainledger.documentName = head.documentName
              this.mainledger.documentIdGeneral = head.documentIdGeneral
              this.mainledger.BookIdGeneral = head.documentIdGeneral
              this.mainledger.ConnName = head.ConnName
              this.mainledger.ConnId = head.ConnId
              this.mainledger.MLFree1 = head.MLFree1
            }
            if (!llaID) {
              this.mainledger = defMainLedger()
              this.mainledger = {...this.mainledger, ...defNew()}
              this.mainledger.BookTypeID = 29
              this.mainledger.MLType = 3
              this.mainledger.BookName = 'Zatvaranje'
              this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
              this.mainledger.documentName = 'Ledger'
              this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-Ledger-' + this.mainledger.BookID
              this.mainledger.BookIdGeneral = this.$store.state.companyName + '-' + this.$store.state.businessYear + '-Ledger-' + this.mainledger.BookID
              this.mainledger.ConnName = 'Zatvaranje'
              this.mainledger.ConnId = this.$store.state.businessYear ? this.$store.state.businessYear : 2021
              this.mainledger.MLFree1 = 'Zatvaranje '  + this.$store.state.businessYear
              const have2 = await MainLedgerService.post(this.mainledger)
              if (have2 && have2.data.mainledger && have2.data.mainledger.id > 0) {
                llaID = have2.data.mainledger.id
              }
            }
            if (!llaID) {
              console.log('ERROR dont have id')
              this.error = 'Greska u konekciji na bazu. Error database connection. NO ID '
              this.alert = true
              this.typeError = 'error'
            }
            if (this.items.length === 0) {
              this.error = 'Greska! Error!, Bruto bilanca je prazna ! There is no items in balance sheet !'
              this.alert = true
              this.typeError = 'error'
            }
            this.newItems = []

            // close FOUR
            let totalFour = 0
            if (this.items.length > 0) {
              this.items.map(item => {
                const fromA = parseInt(item.account4.substring(0,4))
                const toA = parseInt(item.account4.substring(0,4))
                if (fromA >= 4000 && toA <= 4899) {
                  this.initNew(llaID)
                  //this.newItem.id = this.getRndInteger(1000000000, 9000000000)
                  this.newItem.Description = 'Zatvaranje '
                  this.newItem.account1 = item.account1
                  this.newItem.account2 = item.account2
                  this.newItem.account3 = item.account3
                  this.newItem.account4 = item.account4

                  this.newItem.Debit = 0
                  this.newItem.DebitForegin = 0
                  this.newItem.Credit = round(parseNum(item.totalDebit), 2)
                  this.newItem.CreditForegin = round((parseNum(item.totalDebit) / this.exRate), 2)
                  totalFour += round(parseNum(item.totalDebit), 2)
                  this.newItem.account4Description = this.checkAcc(item.account4)
                  this.newItems.push(this.newItem)
                }
              })

              this.initNew(llaID)
              this.newItem.Description = 'Zatvaranje '
              this.newItem.account1 = '4'
              this.newItem.account2 = '49'
              this.newItem.account3 = '491'
              this.newItem.account4 = '4912'

              this.newItem.Debit = round(totalFour, 2)
              this.newItem.DebitForegin = round((totalFour / this.exRate), 2)
              this.newItem.Credit = 0
              this.newItem.CreditForegin = 0
              this.newItem.account4Description = this.checkAcc('4910')
              this.newItems.push(this.newItem)

              this.initNew(llaID)
              this.newItem.Description = 'Zatvaranje '
              this.newItem.account1 = '7'
              this.newItem.account2 = '79'
              this.newItem.account3 = '790'
              this.newItem.account4 = '7900'

              this.newItem.Debit = round(totalFour, 2)
              this.newItem.DebitForegin = round((totalFour / this.exRate), 2)
              this.newItem.Credit = 0
              this.newItem.CreditForegin = 0
              this.newItem.account4Description = this.checkAcc('7900')
              this.newItems.push(this.newItem)

              this.initNew(llaID)
              this.newItem.Description = 'Zatvaranje '
              this.newItem.account1 = '4'
              this.newItem.account2 = '49'
              this.newItem.account3 = '491'
              this.newItem.account4 = '4912'

              this.newItem.Debit = 0
              this.newItem.DebitForegin = 0
              this.newItem.Credit = round(totalFour, 2)
              this.newItem.CreditForegin = round((totalFour / this.exRate), 2)
              this.newItem.account4Description = this.checkAcc('4910')
              this.newItems.push(this.newItem)

            }

            // close SEVEN - expenses
            let totalSeven = 0
            if (this.items.length > 0) {
              this.items.map(item => {
                const fromA = parseInt(item.account4.substring(0,4))
                const toA = parseInt(item.account4.substring(0,4))
                if (fromA >= 7000 && toA <= 7499) {
                  this.initNew(llaID)
                  //this.newItem.id = this.getRndInteger(1000000000, 9000000000)
                  this.newItem.Description = 'Zatvaranje '
                  this.newItem.account1 = item.account1
                  this.newItem.account2 = item.account2
                  this.newItem.account3 = item.account3
                  this.newItem.account4 = item.account4

                  this.newItem.Debit = 0
                  this.newItem.DebitForegin = 0
                  this.newItem.Credit = round(parseNum(item.totalDebit), 2)
                  this.newItem.CreditForegin = round((parseNum(item.totalDebit) / this.exRate), 2)
                  totalSeven += round(parseNum(item.totalDebit), 2)
                  this.newItem.account4Description = this.checkAcc(item.account4)
                  this.newItems.push(this.newItem)
                }
              })

              if (round(totalSeven,2) > 0) {
                this.initNew(llaID)
                this.newItem.Description = 'Zatvaranje '
                this.newItem.account1 = '7'
                this.newItem.account2 = '79'
                this.newItem.account3 = '790'
                this.newItem.account4 = '7900'

                this.newItem.Debit = round(totalSeven, 2)
                this.newItem.DebitForegin = round((totalSeven / this.exRate), 2)
                this.newItem.Credit = 0
                this.newItem.CreditForegin = 0
                this.newItem.account4Description = this.checkAcc('7900')
                this.newItems.push(this.newItem)
              }
            }

            // close SEVEN Second - recivables
            let totalSevenS = 0
            if (this.items.length > 0) {
              this.items.map(item => {
                const fromA = parseInt(item.account4.substring(0,4))
                const toA = parseInt(item.account4.substring(0,4))
                if (fromA >= 7500 && toA <= 7899) {
                  this.initNew(llaID)
                  //this.newItem.id = this.getRndInteger(1000000000, 9000000000)
                  this.newItem.Description = 'Zatvaranje '
                  this.newItem.account1 = item.account1
                  this.newItem.account2 = item.account2
                  this.newItem.account3 = item.account3
                  this.newItem.account4 = item.account4

                  this.newItem.Debit = round(parseNum(item.totalCredit), 2)
                  this.newItem.DebitForegin = round((parseNum(item.totalCredit) / this.exRate), 2)
                  this.newItem.Credit = 0
                  this.newItem.CreditForegin = 0
                  totalSevenS += round(parseNum(item.totalDebit), 2)
                  this.newItem.account4Description = this.checkAcc(item.account4)
                  this.newItems.push(this.newItem)
                }
              })

              if (round(totalSevenS, 2) > 0) {
                this.initNew(llaID)
                this.newItem.Description = 'Zatvaranje '
                this.newItem.account1 = '7'
                this.newItem.account2 = '79'
                this.newItem.account3 = '790'
                this.newItem.account4 = '7900'

                this.newItem.Debit = 0
                this.newItem.DebitForegin = 0
                this.newItem.Credit = round(totalSevenS, 2)
                this.newItem.CreditForegin = round((totalSevenS / this.exRate), 2)
                this.newItem.account4Description = this.checkAcc('7900')
                this.newItems.push(this.newItem)
              }
            }

            // if is profit
            if (round(totalSevenS,2) > round(totalSeven,2)) {
              this.initNew(llaID)
                this.newItem.Description = 'Zatvaranje '
                this.newItem.account1 = '7'
                this.newItem.account2 = '79'
                this.newItem.account3 = '790'
                this.newItem.account4 = '7900'

                this.newItem.Debit = round(totalSevenS - totalSeven, 2)
                this.newItem.DebitForegin = round(((totalSevenS - totalSeven) / this.exRate), 2)
                this.newItem.Credit = 0
                this.newItem.CreditForegin = 0
                this.newItem.account4Description = this.checkAcc('7900')
                this.newItems.push(this.newItem)

                this.initNew(llaID)
                this.newItem.Description = 'Zatvaranje '
                this.newItem.account1 = '8'
                this.newItem.account2 = '80'
                this.newItem.account3 = '804'
                this.newItem.account4 = '8040'

                this.newItem.Debit = 0
                this.newItem.DebitForegin = 0
                this.newItem.Credit = round(totalSevenS - totalSeven, 2)
                this.newItem.CreditForegin = round(((totalSevenS - totalSeven) / this.exRate), 2)
                this.newItem.account4Description = this.checkAcc('8040')
                this.newItems.push(this.newItem)
            }


            // if is loss
            if (round(totalSeven,2) > round(totalSevenS,2)) {
                this.initNew(llaID)
                this.newItem.Description = 'Zatvaranje '
                this.newItem.account1 = '7'
                this.newItem.account2 = '79'
                this.newItem.account3 = '790'
                this.newItem.account4 = '7900'

                this.newItem.Debit = 0
                this.newItem.DebitForegin = 0
                this.newItem.Credit = round(totalSeven - totalSevenS, 2)
                this.newItem.CreditForegin = round(((totalSeven - totalSevenS) / this.exRate), 2)
                this.newItem.account4Description = this.checkAcc('7900')
                this.newItems.push(this.newItem)

                this.initNew(llaID)
                this.newItem.Description = 'Zatvaranje '
                this.newItem.account1 = '8'
                this.newItem.account2 = '80'
                this.newItem.account3 = '804'
                this.newItem.account4 = '8041'

                this.newItem.Debit = round(totalSeven - totalSevenS, 2)
                this.newItem.DebitForegin = round(((totalSeven - totalSevenS) / this.exRate), 2)
                this.newItem.Credit = 0
                this.newItem.CreditForegin = 0
                this.newItem.account4Description = this.checkAcc('8041')
                this.newItems.push(this.newItem)
            }

            if (this.newItems && this.newItems.length > 0) {
              // save all to database
              await MainLedgerDetailService.saveitems(this.newItems)
              .then (response => {
                console.log("response", response)
                alert('PZatvaranje uspjesno obavljeno!')
                }
              )
              .catch(err => {
                console.log("response", err)
                alert('GRESKA! ERROR! Zatvaranje NIJE uspjelo!')
              })
            }


          }
        } catch(err) {
          this.error = err
        }
      },

      async copyFinishedYear() {
        try {
          // check if exist
          const currYear = (this.$store.state.businessYear ? this.$store.state.businessYear : 0)
          const msgAmo = ('Sigurni ste da je zatvorena poslovna godina  ' + currYear + ' i da želite prebaciti bruto bilancu u početno stanje ' + (currYear + 1) +  ' ?') 
          if (confirm(msgAmo)) {
            this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
            this.mainQuery.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear + 1 : 2021
            this.mainQuery.BookTypeID = 21
            this.mainQuery.MLType = 3
            this.mainQuery.ConnId = this.$store.state.businessYear ? this.$store.state.businessYear + 1 : 2021
            let llaID = null
            const haveIt = await MainLedgerService.index(this.mainQuery)
            
            if (haveIt && haveIt.data.documents && haveIt.data.documents.length > 0) {
              // have mainledger close, use it
              // const head = haveIt.data.documents[0]
              const head = haveIt.data.documents.find(tem => tem.MLFree1 === ('PSB '  + (this.$store.state.businessYear + 1)))
              if (head) {
                llaID = head.id

                this.mainledger = defMainLedger()
                this.mainledger = {...this.mainledger, ...defNew()}
                this.mainledger.BookTypeID = head.BookTypeID
                this.mainledger.MLType = head.MLType
                this.mainledger.BookName = head.BookName
                this.mainledger.BookID = head.BookID
                this.mainledger.documentName = head.documentName
                this.mainledger.documentIdGeneral = head.documentIdGeneral
                this.mainledger.BookIdGeneral = head.documentIdGeneral
                this.mainledger.ConnName = head.ConnName
                this.mainledger.ConnId = head.ConnId
                this.mainledger.MLFree1 = head.MLFree1
                this.mainledger.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear + 1 : 2021
                this.mainledger.BookingDate = dayjs([this.$store.state.businessYear + 1]).startOf('year').format('YYYY-MM-DD')
              }
            }
            if (!llaID) {
              this.mainledger = defMainLedger()
              this.mainledger = {...this.mainledger, ...defNew()}
              this.mainledger.BookTypeID = 21
              this.mainledger.MLType = 3
              this.mainledger.BookName = 'PS'
              this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
              this.mainledger.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear + 1 : 2021
              this.mainledger.BookingDate = dayjs([this.$store.state.businessYear + 1]).startOf('year').format('YYYY-MM-DD')
              this.mainledger.documentName = 'Ledger'
              this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + (this.$store.state.businessYear + 1) + '-Ledger-' + this.mainledger.BookID
              this.mainledger.BookIdGeneral = this.$store.state.companyName + '-' + (this.$store.state.businessYear + 1) + '-Ledger-' + this.mainledger.BookID
              this.mainledger.ConnName = 'PSB'
              this.mainledger.ConnId = this.$store.state.businessYear ? (this.$store.state.businessYear + 1) : 2021
              this.mainledger.MLFree1 = 'PSB '  + (this.$store.state.businessYear + 1)
              const have2 = await MainLedgerService.post(this.mainledger)
              if (have2 && have2.data.mainledger && have2.data.mainledger.id > 0) {
                llaID = have2.data.mainledger.id
              }
            }
            if (!llaID) {
              console.log('ERROR dont have id')
              this.error = 'Greska u konekciji na bazu. Error database connection. NO ID '
              this.alert = true
              this.typeError = 'error'
            }
            if (this.items.length === 0) {
              this.error = 'Greska! Error!, Bruto bilanca je prazna ! There is no items in balance sheet !'
              this.alert = true
              this.typeError = 'error'
            }
            this.newItems = []

            if (this.items.length > 0) {
              this.items.map(item => {

                const debit = round(parseNum(item.totalDebit)/ this.exRate, 2)
                const credit = round(parseNum(item.totalCredit)/ this.exRate, 2)

                if (debit != 0 || credit != 0) {
                  this.initNew(llaID)
                  //this.newItem.id = this.getRndInteger(1000000000, 9000000000)
                  this.newItem.Description = 'PS ' + (this.$store.state.businessYear + 1)
                  this.newItem.account1 = item.account1
                  this.newItem.account2 = item.account2
                  this.newItem.account3 = item.account3
                  this.newItem.account4 = item.account4

                  if (debit > credit ) {
                    this.newItem.Debit = round((debit - credit), 2)
                    this.newItem.DebitForegin = round((debit - credit), 2)
                    this.newItem.Credit = 0
                    this.newItem.CreditForegin = 0
                  }
                  if (credit > debit ) {
                    this.newItem.Debit = 0
                    this.newItem.DebitForegin = 0 
                    this.newItem.Credit = round((credit - debit), 2)
                    this.newItem.CreditForegin = round((credit - debit), 2)
                  }
                  this.newItem.account4Description = this.checkAcc(item.account4)
                  this.newItem.BusinessYear = this.$store.state.businessYear ? this.$store.state.businessYear + 1 : 2021
                  this.newItem.BookingDate = dayjs([this.$store.state.businessYear + 1]).startOf('year').format('YYYY-MM-DD')
                  this.newItem.bookTypeSelected = 21
                  this.newItems.push(this.newItem)
                }
              })
            }

            if (this.newItems && this.newItems.length > 0) {
              // save all to database
              await MainLedgerDetailService.saveitems(this.newItems)
              .then (response => {
                console.log("response", response)
                alert('Prebacivanje bilance uspjesno obavljeno!')
                }
              )
              .catch(err => {
                console.log("response", err)
                alert('GRESKA! ERROR! Prebacivanje bilance NIJE uspjelo!')
              })
            }
            console.log('New items', this.newItems)
            
          }
        } catch(err) {
          this.error = err
        }
      },
      async closeBuyerSupplier() {
        try {
          
          // check if exist
          const msgAmo = ('Sigurni ste da želite prebacivanje salda konta iz  ' + (this.$store.state.businessYear ? this.$store.state.businessYear : 0) + 'u poslovnu godinu ' + (this.$store.state.businessYear ? parseInt(this.$store.state.businessYear) + 1 : 0) + '?') 
          if (confirm(msgAmo)) {

            const newYear = this.$store.state.businessYear ? parseInt(this.$store.state.businessYear) + 1 : 2022
            this.mainQuery.CompanyId = this.$store.state.companyid ? this.$store.state.companyid : 1
            this.mainQuery.businessYear = this.$store.state.businessYear ? parseInt(this.$store.state.businessYear) + 1  : 2022
            this.mainQuery.BookTypeID = 21
            this.mainQuery.MLType = 3
            this.mainQuery.ConnId = newYear
            this.mainQuery.MLFree1 = 'PS '  + (parseInt(this.$store.state.businessYear) + 1)

            let llaID = null
            // ALWAYS open new !!!! Why ? on open statement is not possible to select all 

            // const haveIt = await MainLedgerService.index(this.mainQuery)
            
            // if (haveIt && haveIt.data.documents && haveIt.data.documents.length > 0) {
            //   // have mainledger close, use it
            //   const head = haveIt.data.documents[0]
            //   llaID = head.id
            //   this.mainledger = head
            //   // this.mainledger = {...this.mainledger, ...defNew()}
            //   // this.mainledger.BookTypeID = head.BookTypeID
            //   // this.mainledger.MLType = head.MLType
            //   // this.mainledger.BookName = head.BookName
            //   // this.mainledger.BookID = head.BookID
            //   // this.mainledger.documentName = head.documentName
            //   // this.mainledger.documentIdGeneral = head.documentIdGeneral
            //   // this.mainledger.BookIdGeneral = head.documentIdGeneral
            //   // this.mainledger.ConnName = head.ConnName
            //   // this.mainledger.ConnId = head.ConnId
            //   // this.mainledger.MLFree1 = head.MLFree1
            // }
            if (!llaID) {
              const fromAccount = this.selectA4.account4 ? this.selectA4.account4 : null
              const toAccount = this.selectA4to.account4 ? this.selectA4to.account4 : null

              this.mainledger = defMainLedger()
              this.mainledger = {...this.mainledger, ...defNew()}
              this.mainledger.BusinessYear = newYear
              this.mainledger.BookTypeID = 21
              this.mainledger.MLType = 3
              this.mainledger.BookName = 'PS'
              this.mainledger.BookID = (await MainLedgerService.choose(this.mainQuery)).data.docNumber
              this.mainledger.documentName = 'Ledger'
              this.mainledger.documentIdGeneral = this.$store.state.companyName + '-' + newYear  + '-Ledger-' + this.mainledger.BookID
              this.mainledger.BookIdGeneral = this.$store.state.companyName + '-' + newYear  + '-Ledger-' + this.mainledger.BookID
              this.mainledger.ConnName = 'PS'
              this.mainledger.ConnId = newYear
              this.mainledger.MLFree1 = 'PS '  + newYear + ', ' + fromAccount + '-' + toAccount
              this.mainledger.BookingDate = dayjs([newYear]).startOf('year').format('YYYY-MM-DD')
              const have2 = await MainLedgerService.post(this.mainledger)
              if (have2 && have2.data.mainledger && have2.data.mainledger.id > 0) {
                llaID = have2.data.mainledger.id
                this.mainledger = have2.data.mainledger
              }
            }
            if (!llaID) {
              console.log('ERROR dont have id')
              this.error = 'Greska u konekciji na bazu. Error database connection. NO ID '
              this.alert = true
              this.typeError = 'error'
            }
            if (this.items.length === 0) {
              this.error = 'Greska! Error!, Otvorene stavke tablica je prazna ! There are no items in open statements table !'
              this.alert = true
              this.typeError = 'error'
            }

            // 
            let totalDebit = 0
            let totalCredit = 0

            let nrItems = 0
            // const exRate = this.exRate
            if (!this.newItems.length) {
              alert('Greška! Tabela za prebacivanje je prazna! ')
            }

            const psItems = []
            if (this.newItems) {
              this.newItems.map(item => {
                if (item.detItems) {
                  item.detItems.map(detItem => {
                    console.log('detItem = ', detItem)
                    const psItem = {}
                    psItem.databaseMLID = llaID
                    psItem.CompanyId = this.mainledger.CompanyId ? this.mainledger.CompanyId : 1
                    psItem.CompanyNameDatabase = this.mainledger.CompanyNameDatabase ? this.mainledger.CompanyNameDatabase : ''
                    psItem.BusinessYear = newYear
                    psItem.documentName = this.mainledger.documentName ? this.mainledger.documentName : ''
                    psItem.documentIdGeneral = this.mainledger.documentIdGeneral
                    psItem.UserId = this.mainledger.UserId ? this.mainledger.UserId : 0
                    psItem.email = this.mainledger.email ? this.mainledger.email : ''
                    psItem.First = this.mainledger.First ? this.mainledger.First : ''
                    psItem.Last = this.mainledger.Last ? this.mainledger.Last : ''
                    psItem.MLType = this.mainledger.documentSide ? this.mainledger.documentSide : 1
                    psItem.BookID = this.mainledger.BookID ? this.mainledger.BookID : 0
                    psItem.BookTypeID = this.mainledger.BookTypeID ? this.mainledger.BookTypeID : 0
                    psItem.BookName = this.mainledger.BookName ? this.mainledger.BookName : ''
                    psItem.BookIdGeneral = this.mainledger.BookIdGeneral ? this.mainledger.BookIdGeneral : ''

                    psItem.BookingDate = this.mainledger.BookingDate ? this.mainledger.BookingDate : null
                    psItem.account1 = detItem.account1 ? detItem.account1 : ''
                    psItem.account2 = detItem.account2 ? detItem.account2 : ''
                    psItem.account3 = detItem.account3 ? detItem.account3 : ''
                    psItem.account4 = detItem.account4 ? detItem.account4 : ''

                    psItem.Credit = round((parseFloat(detItem.Credit) / this.exRate), 2)
                    psItem.CreditForegin = round((parseFloat(detItem.Credit) / this.exRate), 2)
                    psItem.Debit =  round((parseFloat(detItem.Debit) / this.exRate) ,2)
                    psItem.DebitForegin = round((parseFloat(detItem.Debit) / this.exRate),2)
                    psItem.CreditDebit =  round((parseFloat(detItem.CreditDebit)/ this.exRate), 2)

                    psItem.totalCredit =  round((parseFloat(detItem.Credit)/ this.exRate), 2)
                    psItem.totalDebit =  round((parseFloat(detItem.Debit)/ this.exRate), 2)
                    psItem.totalDiff =  round((parseFloat(detItem.CreditDebit)/ this.exRate), 2)
                    totalCredit += round((parseFloat(detItem.Credit)/ this.exRate), 2)
                    totalDebit += round((parseFloat(detItem.Debit)/ this.exRate), 2)

                    console.log('psItem.Credit', psItem.Credit)
                    console.log('psItem.Debit', psItem.Debit)

                    // psItem.Credit = detItem.Credit ? parseNum(detItem.Credit, 2) : 0
                    // psItem.CreditForegin = detItem.CreditForegin ? parseNum(detItem.CreditForegin, 2) : 0
                    // psItem.Debit = detItem.Debit ? parseNum(detItem.Debit, 2) : 0
                    // psItem.DebitForegin = detItem.DebitForegin ? parseNum(detItem.DebitForegin, 2) : 0
                    // psItem.CreditDebit = detItem.CreditDebit ? parseNum(detItem.CreditDebit, 2) : 0

                    // psItem.totalCredit = detItem.totalCredit ? parseNum(detItem.totalCredit, 2) : 0
                    // psItem.totalDebit = detItem.totalDebit ? parseNum(detItem.totalDebit, 2) : 0
                    // psItem.totalDiff = detItem.totalDiff ? parseNum(detItem.totalDiff, 2) : 0
                    // totalCredit += parseNum(detItem.Credit, 2)
                    // totalDebit += parseNum(detItem.Debit, 2)

                    psItem.InvoiceNumber = detItem.InvoiceNumber ? detItem.InvoiceNumber : ''
                    psItem.InvoiceDate = detItem.InvoiceDate ? detItem.InvoiceDate : null
                    psItem.InvoicePaymentDeadlineDate = detItem.InvoicePaymentDeadlineDate ? detItem.InvoicePaymentDeadlineDate : null
                    psItem.Description = detItem.Description ? 'PS-' + detItem.Description : 0

                    psItem.invoiceInID = detItem.invoiceInID ? parseInt(detItem.invoiceInID) : null
                    psItem.invoiceInNumber = detItem.invoiceInNumber ? parseInt(detItem.invoiceInNumber) : null
                    psItem.BusinessYearInvoice = detItem.BusinessYearInvoice ? parseInt(detItem.BusinessYearInvoice) : 2024

                    psItem.invoiceOutID = detItem.invoiceOutID ? parseInt(detItem.invoiceOutID) : null
                    psItem.invoiceOutNumber = detItem.invoiceOutNumber ? parseInt(detItem.invoiceOutNumber) : null
                       
                    if (item.partner) {
                      psItem.partnerId = item.partner.id ? parseInt(item.partner.id) : null
                      psItem.partnerName = item.partner.partnerName ? item.partner.partnerName : null
                      psItem.partnerVATID = item.partner.partnerVATID ? item.partner.partnerVATID : null
                      psItem.partnerIBAN = item.partner.partnerBankIBAN ? item.partner.partnerBankIBAN : null
                      psItem.partneremail = item.partner.partneremail ? item.partner.partneremail : null
                      psItem.partnerStreet = item.partner.partnerStreet ? item.partner.partnerStreet : null
                      psItem.partnerBuildingNameNumber = item.partner.partnerBuildingNameNumber ? item.partner.partnerBuildingNameNumber : null
                      psItem.partnerCity = item.partner.partnerCity ? item.partner.partnerCity : null
                      psItem.partnerPostcode = item.partner.partnerPostcode ? item.partner.partnerPostcode : null
                    }
                    psItem.HomeCurrency = detItem.HomeCurrency ? detItem.HomeCurrency : 'EUR'
                    psItem.HomeCurrencyPrimary =  false
                    psItem.ForeignCurrency = detItem.ForeignCurrency ? detItem.ForeignCurrency : 'EUR'
                    psItem.ForeignCurrencyPrimary = false
                    psItem.ExRate = detItem.ExRate ? parseFloat(detItem.ExRate) : 7.5345

                    psItem.ConnName = this.mainledger.ConnName ? this.mainledger.ConnName : ''
                    psItem.ConnId = this.mainledger.ConnId ? this.mainledger.ConnId : 0

                    psItems.push(psItem)
                    nrItems +=1
                  })
                }
              })
            }

            if (psItems && psItems.length > 0) {
              // save all to database
              await MainLedgerDetailService.saveitems(psItems)
              .then (async(response) => {
                  console.log("response", response)
                  const debitTotal = totalDebit ? round(Math.abs(totalDebit),2) : 0
                  const creditTotal = totalCredit ? round(Math.abs(totalCredit),2) : 0

                  if (debitTotal !== 0 || creditTotal !== 0) {
                    this.mainledger.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
                  //this.mainledger.DiffTotal = this.fTotal5
                    if (this.mainledger.DiffTotal === 0) {
                      this.mainledger.ClosedAccount = true
                    }
                  }
                  // if have items but totals are 0
                  if (debitTotal === 0 && creditTotal === 0 && nrItems > 0) {
                    //this.mainledger.DiffTotal = round(debitTotal,2) - round(creditTotal,2)
                    this.mainledger.DiffTotal = this.fTotal5
                    if (this.mainledger.DiffTotal === 0) {
                      this.mainledger.ClosedAccount = true
                    }
                  }
                  this.mainledger.DebitTotal = round((debitTotal), 2)
                  this.mainledger.CreditTotal = round((creditTotal), 2)
                  this.mainledger.DebitForeginTotal = round((debitTotal), 2)
                  this.mainledger.CreditForeginTotal = round((creditTotal), 2)
                  this.mainledger.DiffForeginTotal = round((this.mainledger.DiffTotal), 2)
                  await MainLedgerService.put(this.mainledger)
                  alert('Prebacivanje uspjesno obavljeno!')
                }
              )
              .catch(err => {
                console.log("response", err)
                alert('GRESKA! ERROR! Prebacivanje NIJE uspjelo!')
              })
            }


          }
        } catch(err) {
          this.error = err
        }
      },


  },
  watch: {
     'sameAsFrom' () {
      if (this.sameAsFrom === true) {
        if (this.selectA4.account4) {
          this.selectA4to = this.selectA4
        }
      }
    },
    'showEUR' () {
      if (!this.showEUR || this.showEUR) {
        this.clearAllSets()
      }
    },
    'selectPa'() {
      if (!this.selectPa) {
        this.clearAllSets()
      }
    },
    'selectedMonth' () {
     if  (this.selectedMonth) {
       let y = this.$store.state.businessYear
       let m = this.selectedMonth - 1;
       let firstDay = new Date(y, m, 1);
       let lastDay = new Date(y, m + 1, 0);

      this.startDate = dayjs(firstDay).format('YYYY-MM-DD')
      this.endDate = dayjs(lastDay).format('YYYY-MM-DD')

     }
     if (!this.selectedMonth) {
      this.startDate = dayjs([this.mainledgerAna.BusinessYear]).startOf('year').format('YYYY-MM-DD')
      this.endDate = dayjs([this.mainledgerAna.BusinessYear]).endOf('year').format('YYYY-MM-DD')
     }
   },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style >

</style>
