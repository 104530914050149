import axios from 'axios'
import store from '@/store/store'

export default () => {
  
  return axios.create({
    baseURL: `https://app.roboot.net/backend/`,
    headers: {
      Authorization: `Bearer ${store.state.token}`
    }
  })
}
// ovo je za lokalno
// lokalno http://localhost:3000
// baseURL: `https://app.roboot.net/backend/`
// a ovo je za AWS cegrupa
// evo recimo ovo sad radi, promjenio sam nginx da salje na port 40705,
// AWS roboot https://app.roboot.net/backend/
// AWS ceusluge - https://app.ceusluge.com/backend/
// AWS virtualni - https://app.virtualniured.com/backend/
// AWS cegrupa - https://app.cegrupa.com/backend/
// a za heroku treba biti prazno
// a za expertum http://192.168.11.200:3000/
// a za Pinkys http://194.169.51.9:3000/
// a za Pinkys http://192.168.1.130:3000/
// a za Pinkys ./backend/    - od 04.05.2022.
// a za MV motori http://192.168.0.200:3000/
