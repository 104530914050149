import {round} from '@/snippets/allSnippets'
import store from '@/store/store'

const fullGFI = []
let pItems = []
let pOldItems = []
const exRate = store.getters.NeedDefExRate 

function doAll (allRec) {

  pItems = allRec.pItems
  pOldItems = allRec.pOldItems
  fullGFI.length = 0

  console.log('fullGFI', fullGFI)
 
  gfipod01()
  gfipod1()
  gfipod2()
  gfipod3()
  gfipod4()
  gfipod5()
  gfipod6()
  gfipod7()
  gfipod8()
  gfipod9()
  gfipod10()
  gfipod11()
  gfipod12()
  gfipod13()
  gfipod131()
  gfipod14()
  gfipod15()
  gfipod16()
  gfipod17()
  gfipod18()
  gfipod19()
  gfipod20()
  gfipod21()
  gfipod22()
  gfipod23()
  gfipod24()
  gfipod25()
  gfipod26()
  gfipod27()
  return fullGFI
}

function gfipod01() {
  const nI = {}
  nI.id = 91
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = ''
  nI.col2 = ''
  nI.col3 = 'AKTIVA'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  // const resF1 = doRdgCalc(4, '29', '', 'oneTotal1', 'totalDebit')
  // if (resF1.prevYear) {f_1p = resF1.prevYear }
  // if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  nI.addDash = true
  fullGFI.push(nI)
}
function gfipod1() {

  // ----------------------------------------------------------------AKTIVA
  // >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
  let col5 = 0
  let col6 = 0
  const res1 = doRdgCalc(4, '00', '', 'oneTotal1', 'totalDebit')
  if (res1.prevYear) {col5 = res1.prevYear }
  if (res1.currYear) {col6 = res1.currYear }

  const nI1 = {}
  nI1.id = 1
  nI1.header = true
  nI1.bold = true
  nI1.small = false
  nI1.col1 = 'A.'
  nI1.col2 = '00'
  nI1.col3 = 'POTRAŽIVANJA ZA UPISANI A NEUPLAĆENI KAPITAL'
  nI1.col4 = ''
  nI1.col5 = col5
  nI1.col6 = col6
  fullGFI.push(nI1)

  const nI2 = {}
  nI2.id = 2
  nI2.header = true
  nI2.bold = true
  nI2.small = false
  nI2.col1 = 'B.'
  nI2.col2 = ''
  nI2.col3 = 'DUGOTRAJNA IMOVINA'
  nI2.col4 = ''
  nI2.col5 = null
  nI2.col6 = null
  fullGFI.push(nI2)

  const res2 = doRdgCalc(4, '01', '', 'oneTotal1', 'totalDebit')
  if (res2.prevYear) {col5 = res2.prevYear }
  if (res2.currYear) {col6 = res2.currYear }
 
  const nI3 = {}
  nI3.id = 3
  nI3.header = true
  nI3.bold = true
  nI3.small = false
  nI3.col1 = 'B.I.'
  nI3.col2 = '01'
  nI3.col3 = 'NEMATERIJALNA IMOVINA'
  nI3.col4 = ''
  nI3.col5 = null
  nI3.col6 = null

  let b_1_1p = 0
  let b_1_1c = 0
  const res3 = doRdgCalc(2, '010', '', 'oneTotal1', 'totalDebit')
  if (res3.prevYear) {b_1_1p = res3.prevYear }
  if (res3.currYear) {b_1_1c = res3.currYear }

  const nA = {}
  nA.id = 4
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '010'
  nA.col3 = 'Izdaci za razvoj'
  nA.col4 = ''
  nA.col5 = b_1_1p
  nA.col6 = b_1_1c

  let b_1_2p = 0
  let b_1_2c = 0
  const res4 = doRdgCalc(1, '011', '012', 'oneTotal1', 'totalDebit')
  if (res4.prevYear) {b_1_2p = res4.prevYear }
  if (res4.currYear) {b_1_2c = res4.currYear }
  const nB = {}
  nB.id = 5
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '011, 012'
  nB.col3 = 'Koncesije, patenti, licencije, robne i uslužne marke, softver i ostala prava'
  nB.col4 = ''
  nB.col5 = b_1_2p
  nB.col6 = b_1_2c

  let b_1_3p = 0
  let b_1_3c = 0
  const res5 = doRdgCalc(2, '013', '', 'oneTotal1', 'totalDebit')
  if (res5.prevYear) {b_1_3p = res5.prevYear }
  if (res5.currYear) {b_1_3c = res5.currYear }
  const nC = {}
  nC.id = 6
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '013'
  nC.col3 = 'Goodwill'
  nC.col4 = ''
  nC.col5 = b_1_3p
  nC.col6 = b_1_3c


  let b_1_4p = 0
  let b_1_4c = 0
  const res6 = doRdgCalc(2, '015', '', 'oneTotal1', 'totalDebit')
  if (res6.prevYear) {b_1_4p = res6.prevYear }
  if (res6.currYear) {b_1_4c = res6.currYear }
  const nD = {}
  nD.id = 7
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '015'
  nD.col3 = 'Predujmovi za nabavu nematerijalne imovine'
  nD.col4 = ''
  nD.col5 = b_1_4p
  nD.col6 = b_1_4c

  let b_1_5p = 0
  let b_1_5c = 0
  const res7 = doRdgCalc(2, '016', '', 'oneTotal1', 'totalDebit')
  if (res7.prevYear) {b_1_5p = res7.prevYear }
  if (res7.currYear) {b_1_5c = res7.currYear }
  const nE = {}
  nE.id = 8
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '016'
  nE.col3 = 'Nematerijalna imovina u pripremi'
  nE.col4 = ''
  nE.col5 = b_1_5p
  nE.col6 = b_1_5c

  let b_1_6p = 0
  let b_1_6c = 0
  const res8 = doRdgCalc(2, '014', '', 'oneTotal1', 'totalDebit')
  if (res8.prevYear) {b_1_6p = res8.prevYear }
  if (res8.currYear) {b_1_6c = res8.currYear }
  const nF = {}
  nF.id = 9
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '014'
  nF.col3 = 'Ostala nematerijalna imovina'
  nF.col4 = ''
  nF.col5 = round(b_1_6p, 2)
  nF.col6 = round(b_1_6c, 2)

  nI3.col5 = round(b_1_1p + b_1_2p + b_1_3p + b_1_4p + b_1_5p + b_1_6p, 2)
  nI3.col6 = round(b_1_1c + b_1_2c + b_1_3c + b_1_4c + b_1_5c + b_1_6c, 2)
  fullGFI.push(nI3)
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF) 

}

function gfipod2() {

  const nI = {}
  nI.id = 10
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'B.II.'
  nI.col2 = '02,03,04,05'
  nI.col3 = 'MATERIJALNA IMOVINA'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let b_2_1p = 0
  let b_2_1c = 0
  const resB2_1 = doRdgCalc(1, '020', '021', 'oneTotal1', 'totalDebit')
  if (resB2_1.prevYear) {b_2_1p = resB2_1.prevYear }
  if (resB2_1.currYear) {b_2_1c = resB2_1.currYear }
  const nA = {}
  nA.id = 11
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '020,021'
  nA.col3 = 'Zemljište'
  nA.col4 = ''
  nA.col5 = round(b_2_1p ,2)
  nA.col6 = round(b_2_1c ,2)

  let b_2_2p = 0
  let b_2_2c = 0
  const resB2_2 = doRdgCalc(1, '023', '024', 'oneTotal2', 'totalCredit')
  if (resB2_2.prevYear) {b_2_2p = resB2_2.prevYear }
  if (resB2_2.currYear) {b_2_2c = resB2_2.currYear }
  const nB = {}
  nB.id = 12
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '023, 024'
  nB.col3 = 'Građevinski objekti'
  nB.col4 = ''
  nB.col5 = round(b_2_2p ,2)
  nB.col6 = round(b_2_2c ,2)

  let b_2_3p = 0
  let b_2_3c = 0
  const resB2_3 = doRdgCalc(1, '030', '031', 'oneTotal2', 'totalCredit')
  if (resB2_3.prevYear) {b_2_3p = resB2_3.prevYear }
  if (resB2_3.currYear) {b_2_3c = resB2_3.currYear }
  const nC = {}
  nC.id = 13
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '030,031'
  nC.col3 = 'Postrojenja i oprema'
  nC.col4 = ''
  nC.col5 = round(b_2_3p ,2)
  nC.col6 = round(b_2_3c ,2)

  let b_2_4p = 0
  let b_2_4c = 0
  const resB2_4 = doRdgCalc(2, '032', '', 'oneTotal2', 'totalCredit')
  if (resB2_4.prevYear) {b_2_4p = resB2_4.prevYear }
  if (resB2_4.currYear) {b_2_4c = resB2_4.currYear }
  const nD = {}
  nD.id = 14
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '032'
  nD.col3 = 'Alati, pogonski inventar i transportna imovina'
  nD.col4 = ''
  nD.col5 = round(b_2_4p ,2)
  nD.col6 = round(b_2_4c ,2)

  
  let b_2_5p = 0
  let b_2_5c = 0
  const resB2_5 = doRdgCalc(1, '040', '041', 'oneTotal2', 'totalCredit')
  if (resB2_5.prevYear) {b_2_5p = resB2_5.prevYear }
  if (resB2_5.currYear) {b_2_5c = resB2_5.currYear }
  const nE = {}
  nE.id = 15
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '040, 041'
  nE.col3 = 'Biološka imovina'
  nE.col4 = ''
  nE.col5 = round(b_2_5p ,2)
  nE.col6 = round(b_2_5c ,2)

  let b_2_61p = 0
  let b_2_61c = 0
  const resB2_61 = doRdgCalc(2, '026', '', 'oneTotal2', 'totalCredit')
  if (resB2_61.prevYear) {b_2_61p = resB2_61.prevYear }
  if (resB2_61.currYear) {b_2_621c = resB2_61.currYear }
  let b_2_62p = 0
  let b_2_62c = 0
  const resB2_62 = doRdgCalc(2, '036', '', 'oneTotal2', 'totalCredit')
  if (resB2_62.prevYear) {b_2_62p = resB2_62.prevYear }
  if (resB2_62.currYear) {b_2_62c = resB2_62.currYear }
  let b_2_63p = 0
  let b_2_63c = 0
  const resB2_63 = doRdgCalc(2, '046', '', 'oneTotal2', 'totalCredit')
  if (resB2_63.prevYear) {b_2_63p = resB2_63.prevYear }
  if (resB2_63.currYear) {b_2_63c = resB2_63.currYear }
  const nF = {}
  nF.id = 16
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '026, 036, 046'
  nF.col3 = 'Predujmovi za materijalnu imovinu'
  nF.col4 = ''
  nF.col5 = round(b_2_61p ,2) + round(b_2_62p, 2) + round(b_2_63p, 2)
  nF.col6 = round(b_2_61c ,2) + round(b_2_62c, 2) + round(b_2_63c, 2)

  
  let b_2_71p = 0
  let b_2_71c = 0
  const resB2_71 = doRdgCalc(2, '027', '', 'oneTotal2', 'totalCredit')
  if (resB2_71.prevYear) {b_2_71p = resB2_71.prevYear }
  if (resB2_71.currYear) {b_2_721c = resB2_71.currYear }
  let b_2_72p = 0
  let b_2_72c = 0
  const resB2_72 = doRdgCalc(2, '037', '', 'oneTotal2', 'totalCredit')
  if (resB2_72.prevYear) {b_2_72p = resB2_72.prevYear }
  if (resB2_72.currYear) {b_2_72c = resB2_72.currYear }
  let b_2_73p = 0
  let b_2_73c = 0
  const resB2_73 = doRdgCalc(2, '046', '', 'oneTotal2', 'totalCredit')
  if (resB2_73.prevYear) {b_2_73p = resB2_73.prevYear }
  if (resB2_73.currYear) {b_2_73c = resB2_73.currYear }
  const nG = {}
  nG.id = 17
  nG.header = false
  nG.bold = true
  nG.small = false
  nG.col1 = '7.'
  nG.col2 = '027, 037, 047'
  nG.col3 = 'Materijalna imovina u pripremi'
  nG.col4 = ''
  nG.col5 = round(b_2_71p ,2) + round(b_2_72p, 2) + round(b_2_73p, 2)
  nG.col6 = round(b_2_71c ,2) + round(b_2_72c, 2) + round(b_2_73c, 2)

  let b_2_8p = 0
  let b_2_8c = 0
  const resB2_8 = doRdgCalc(2, '035', '', 'oneTotal2', 'totalCredit')
  if (resB2_8.prevYear) {b_2_8p = resB2_8.prevYear }
  if (resB2_8.currYear) {b_2_8c = resB2_8.currYear }
  const nH = {}
  nH.id = 18
  nH.header = false
  nH.bold = true
  nH.small = false
  nH.col1 = '8.'
  nH.col2 = '035'
  nH.col3 = 'Ostala materijalna imovina'
  nH.col4 = ''
  nH.col5 = round(b_2_8p ,2)
  nH.col6 = round(b_2_8c ,2)

  let b_2_9p = 0
  let b_2_9c = 0
  const resB2_9 = doRdgCalc(4, '05', '', 'oneTotal2', 'totalCredit')
  if (resB2_9.prevYear) {b_2_9p = resB2_9.prevYear }
  if (resB2_9.currYear) {b_2_9c = resB2_9.currYear }
  const nJ = {}
  nJ.id = 19
  nJ.header = false
  nJ.bold = true
  nJ.small = false
  nJ.col1 = '9.'
  nJ.col2 = '05'
  nJ.col3 = 'Ulaganja u nekretnine'
  nJ.col4 = ''
  nJ.col5 = round(b_2_9p ,2)
  nJ.col6 = round(b_2_9c ,2)

  nI.col5 = round(b_2_1p ,2) + round(b_2_2p ,2) + round(b_2_3p ,2) + round(b_2_4p ,2) + round(b_2_5p ,2) + round(b_2_61p ,2) + round(b_2_62p, 2) + round(b_2_63p, 2) + round(b_2_71p ,2) + round(b_2_72p, 2) + round(b_2_73p, 2) + round(b_2_8p ,2) + round(b_2_9p ,2)
  nI.col6 = round(b_2_1c ,2) + round(b_2_2c ,2) + round(b_2_3c ,2) + round(b_2_4c ,2) + round(b_2_5c ,2) + round(b_2_61c ,2) + round(b_2_62c, 2) + round(b_2_63c, 2) + round(b_2_71c ,2) + round(b_2_72c, 2) + round(b_2_73c, 2) + round(b_2_8c ,2) + round(b_2_9c ,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF)
  fullGFI.push(nG)
  fullGFI.push(nH)
  fullGFI.push(nJ)

}

function gfipod3() {
  
  const nI = {}
  nI.id = 20
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'B.III.'
  nI.col2 = '06'
  nI.col3 = 'DUGOTRAJNA FINANCIJSKA IMOVINA'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let b_3_1p = 0
  let b_3_1c = 0
  const resB3_1 = doRdgCalc(5, '0600', '', 'oneTotal2', 'totalCredit')
  if (resB3_1.prevYear) {b_3_1p = resB3_1.prevYear }
  if (resB3_1.currYear) {b_3_1c = resB3_1.currYear }
  const nA = {}
  nA.id = 21
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '0600'
  nA.col3 = 'Ulaganja u udjele (dionice) poduzetnika unutar grupe'
  nA.col4 = ''
  nA.col5 = round(b_3_1p ,2)
  nA.col6 = round(b_3_1c ,2)

  let b_3_2p = 0
  let b_3_2c = 0
  const resB3_2 = doRdgCalc(5, '0601', '', 'oneTotal2', 'totalCredit')
  if (resB3_2.prevYear) {b_3_2p = resB3_2.prevYear }
  if (resB3_2.currYear) {b_3_2c = resB3_2.currYear }
  const nB = {}
  nB.id = 22
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '0601'
  nB.col3 = 'Ulaganja u ostale vrijednosne papire poduzetnika unutar grupe'
  nB.col4 = ''
  nB.col5 = round(b_3_2p ,2)
  nB.col6 = round(b_3_2c ,2)

  let b_3_3p = 0
  let b_3_3c = 0
  const resB3_3 = doRdgCalc(2, '061', '', 'oneTotal2', 'totalCredit')
  if (resB3_3.prevYear) {b_3_3p = resB3_3.prevYear }
  if (resB3_3.currYear) {b_3_3c = resB3_3.currYear }
  const nC = {}
  nC.id = 23
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '061'
  nC.col3 = 'Dani zajmovi, depoziti i slično poduzetnicima unutar grupe'
  nC.col4 = ''
  nC.col5 = round(b_3_3p ,2)
  nC.col6 = round(b_3_3c ,2)

  let b_3_4p = 0
  let b_3_4c = 0
  const resB3_4 = doRdgCalc(5, '0620', '', 'oneTotal2', 'totalCredit')
  if (resB3_4.prevYear) {b_3_4p = resB3_4.prevYear }
  if (resB3_4.currYear) {b_3_4c = resB3_4.currYear }
  const nD = {}
  nD.id = 24
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '0620'
  nD.col3 = 'Ulaganja u udjele (dionice) društava povezanih sudjelujućim interesom'
  nD.col4 = ''
  nD.col5 = round(b_3_4p ,2)
  nD.col6 = round(b_3_4c ,2)

  let b_3_5p = 0
  let b_3_5c = 0
  const resB3_5 = doRdgCalc(5, '0621', '', 'oneTotal2', 'totalCredit')
  if (resB3_5.prevYear) {b_3_5p = resB3_5.prevYear }
  if (resB3_5.currYear) {b_3_5c = resB3_5.currYear }
  const nE = {}
  nE.id = 25
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '0621'
  nE.col3 = 'Ulaganja u ostale vrijednosne papire društava povezanih sudjelujućim interesom'
  nE.col4 = ''
  nE.col5 = round(b_3_5p ,2)
  nE.col6 = round(b_3_5c ,2)

  let b_3_6p = 0
  let b_3_6c = 0
  const resB3_6 = doRdgCalc(2, '063', '', 'oneTotal2', 'totalCredit')
  if (resB3_6.prevYear) {b_3_6p = resB3_6.prevYear }
  if (resB3_6.currYear) {b_3_6c = resB3_6.currYear }
  const nF = {}
  nF.id = 26
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '063'
  nF.col3 = 'Dani zajmovi, depoziti i slično društvima povezanim sudjelujućim interesom'
  nF.col4 = ''
  nF.col5 = round(b_3_6p ,2)
  nF.col6 = round(b_3_6c ,2)

  let b_3_7p = 0
  let b_3_7c = 0
  const resB3_7 = doRdgCalc(2, '064', '', 'oneTotal2', 'totalCredit')
  if (resB3_7.prevYear) {b_3_7p = resB3_7.prevYear }
  if (resB3_7.currYear) {b_3_7c = resB3_7.currYear }
  const nG = {}
  nG.id = 27
  nG.header = false
  nG.bold = true
  nG.small = false
  nG.col1 = '7.'
  nG.col2 = '064'
  nG.col3 = 'Ulaganja u vrijednosne papire'
  nG.col4 = ''
  nG.col5 = round(b_3_7p ,2)
  nG.col6 = round(b_3_7c ,2)

  let b_3_8p = 0
  let b_3_8c = 0
  const resB3_8 = doRdgCalc(2, '065', '', 'oneTotal2', 'totalCredit')
  if (resB3_8.prevYear) {b_3_8p = resB3_8.prevYear }
  if (resB3_8.currYear) {b_3_8c = resB3_8.currYear }
  const nH = {}
  nH.id = 28
  nH.header = false
  nH.bold = true
  nH.small = false
  nH.col1 = '8.'
  nH.col2 = '065'
  nH.col3 = 'Dani zajmovi, depoziti i slično'
  nH.col4 = ''
  nH.col5 = round(b_3_8p ,2)
  nH.col6 = round(b_3_8c ,2)

  let b_3_9p = 0
  let b_3_9c = 0
  const resB3_9 = doRdgCalc(2, '066', '', 'oneTotal2', 'totalCredit')
  if (resB3_9.prevYear) {b_3_9p = resB3_9.prevYear }
  if (resB3_9.currYear) {b_3_9c = resB3_9.currYear }
  const nJ = {}
  nJ.id = 29
  nJ.header = false
  nJ.bold = true
  nJ.small = false
  nJ.col1 = '9.'
  nJ.col2 = '066'
  nJ.col3 = 'Ostala ulaganja koja se obračunavaju metodom udjela'
  nJ.col4 = ''
  nJ.col5 = round(b_3_9p ,2)
  nJ.col6 = round(b_3_9c ,2)

  let b_3_10p = 0
  let b_3_10c = 0
  const resB3_10 = doRdgCalc(2, '067', '', 'oneTotal2', 'totalCredit')
  if (resB3_10.prevYear) {b_3_10p = resB3_10.prevYear }
  if (resB3_10.currYear) {b_3_10c = resB3_10.currYear }
  const nK = {}
  nK.id = 30
  nK.header = false
  nK.bold = true
  nK.small = false
  nK.col1 = '10.'
  nK.col2 = '067'
  nK.col3 = 'Ostala dugotrajna financijska imovina'
  nK.col4 = ''
  nK.col5 = round(b_3_10p ,2)
  nK.col6 = round(b_3_10c ,2)

  nI.col5 = round(b_3_1p ,2) + round(b_3_2p ,2) + round(b_3_3p ,2) + round(b_3_4p ,2) + round(b_3_5p ,2) + round(b_3_6p ,2)  + round(b_3_7p ,2) + round(b_3_8p ,2) + round(b_3_9p ,2) + round(b_3_10p ,2)
  nI.col6 = round(b_3_1c ,2) + round(b_3_2c ,2) + round(b_3_3c ,2) + round(b_3_4c ,2) + round(b_3_5c ,2) + round(b_3_6c ,2)  + round(b_3_7c ,2) + round(b_3_8c ,2) + round(b_3_9c ,2) + round(b_3_10c ,2)

  fullGFI.push(nI)
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF)
  fullGFI.push(nG)
  fullGFI.push(nH)
  fullGFI.push(nJ)
  fullGFI.push(nK)

}

function gfipod4() {

  const nI = {}
  nI.id = 31
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'B.IV.'
  nI.col2 = '07'
  nI.col3 = 'POTRAŽIVANJA'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let b_4_1p = 0
  let b_4_1c = 0
  const resB4_1 = doRdgCalc(2, '010', '', 'oneTotal2', 'totalCredit')
  if (resB4_1.prevYear) {b_4_1p = resB4_1.prevYear }
  if (resB4_1.currYear) {b_4_1c = resB4_1.currYear }
  const nA = {}
  nA.id = 32
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '070'
  nA.col3 = 'Potraživanja od poduzetnika unutar grupe'
  nA.col4 = ''
  nA.col5 = b_4_1p
  nA.col6 = b_4_1c

  let b_4_2p = 0
  let b_4_2c = 0
  const resB4_2 = doRdgCalc(1, '011', '012', 'oneTotal2', 'totalCredit')
  if (resB4_2.prevYear) {b_4_2p = resB4_2.prevYear }
  if (resB4_2.currYear) {b_4_2c = resB4_2.currYear }
  const nB = {}
  nB.id = 33
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '071'
  nB.col3 = 'Potraživanja od društava povezanih sudjelujućim interesom'
  nB.col4 = ''
  nB.col5 = b_4_2p
  nB.col6 = b_4_2c

  let b_4_3p = 0
  let b_4_3c = 0
  const resB4_3 = doRdgCalc(2, '013', '', 'oneTotal2', 'totalCredit')
  if (resB4_3.prevYear) {b_4_3p = resB4_3.prevYear }
  if (resB4_3.currYear) {b_4_3c = resB4_3.currYear }
  const nC = {}
  nC.id = 34
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '072'
  nC.col3 = 'Potraživanja od kupaca'
  nC.col4 = ''
  nC.col5 = b_4_3p
  nC.col6 = b_4_3c

  let b_4_4p = 0
  let b_4_4c = 0
  const resB4_4 = doRdgCalc(2, '015', '', 'oneTotal2', 'totalCredit')
  if (resB4_4.prevYear) {b_4_4p = resB4_4.prevYear }
  if (resB4_4.currYear) {b_4_4c = resB4_4.currYear }
  const nD = {}
  nD.id = 35
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '073, 074, 075, 076 i 077'
  nD.col3 = 'Ostala potraživanja'
  nD.col4 = ''
  nD.col5 = b_4_4p
  nD.col6 = b_4_4c

  nI.col5 = round(b_4_1p + b_4_2p + b_4_3p + b_4_4p, 2)
  nI.col6 = round(b_4_1c + b_4_2c + b_4_3c + b_4_4c, 2)
  fullGFI.push(nI)
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  
  // ----------------------------------------------------------------PASIVA
}

function gfipod5() {

  const nI = {}
  nI.id = 31
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'B.V.'
  nI.col2 = '08'
  nI.col3 = 'ODGOĐENA POREZNA IMOVINA'
  nI.col4 = ''

  let b_5p = 0
  let b_5c = 0
  const resB5 = doRdgCalc(1, '020', '021', 'oneTotal1', 'totalDebit')
  if (resB5.prevYear) {b_5p = resB5.prevYear }
  if (resB5.currYear) {b_5c = resB5.currYear }
  nI.col5 = round(b_5p ,2)
  nI.col6 = round(b_5c ,2)
  fullGFI.push(nI)
}

function gfipod6() {
  const nI = {}
  nI.id = 32
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'C'
  nI.col2 = ''
  nI.col3 = 'KRATKOTRAJNA IMOVINA'
  nI.col4 = ''
  nI.col5 = 0
  nI.col6 = 0
  fullGFI.push(nI)
}

function gfipod7 () {
  const nI = {}
  nI.id = 33
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'C.I.'
  nI.col2 = '3'
  nI.col3 = 'ZALIHE'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let c_2_11p = 0
  let c_2_11c = 0
  const resC2_11 = doRdgCalc(3, '30', '32', 'oneTotal1', 'totalDebit')
  if (resC2_11.prevYear) {c_2_11p = resC2_11.prevYear }
  if (resC2_11.currYear) {c_2_11c = resC2_11.currYear }
  let c_2_12p = 0
  let c_2_12c = 0
  const resC2_12 = doRdgCalc(3, '35', '36', 'oneTotal1', 'totalDebit')
  if (resC2_12.prevYear) {c_2_12p = resC2_12.prevYear }
  if (resC2_12.currYear) {c_2_12c = resC2_12.currYear }
  const nA = {}
  nA.id = 34
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '30, 31, 32, 35, 36 '
  nA.col3 = 'Sirovine i materijal'
  nA.col4 = ''
  nA.col5 = round(c_2_11p + c_2_12p ,2)
  nA.col6 = round(c_2_11c + c_2_12c ,2)

  let c_2_2p = 0
  let c_2_2c = 0
  const resC2_2 = doRdgCalc(3, '60', '61', 'oneTotal1', 'totalDebit')
  if (resC2_2.prevYear) {c_2_2p = resC2_2.prevYear }
  if (resC2_2.currYear) {c_2_2c = resC2_2.currYear }
  const nB = {}
  nB.id = 35
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '60, 61'
  nB.col3 = 'Proizvodnja u tijeku'
  nB.col4 = ''
  nB.col5 = round(c_2_2p ,2)
  nB.col6 = round(c_2_2c ,2)

  let c_2_3p = 0
  let c_2_3c = 0
  const resC2_3 = doRdgCalc(3, '63', '64', 'oneTotal1', 'totalDebit')
  if (resC2_3.prevYear) {c_2_3p = resC2_3.prevYear }
  if (resC2_3.currYear) {c_2_3c = resC2_3.currYear }
  const nC = {}
  nC.id = 36
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '63, 64'
  nC.col3 = 'Gotovi proizvodi'
  nC.col4 = ''
  nC.col5 = round(c_2_3p ,2)
  nC.col6 = round(c_2_3c ,2)

  let c_2_41p = 0
  let c_2_41c = 0
  const resC2_41 = doRdgCalc(4, '66', '', 'oneTotal1', 'totalDebit')
  if (resC2_41.prevYear) {c_2_41p = resC2_41.prevYear }
  if (resC2_41.currYear) {c_2_41c = resC2_41.currYear }
  let c_2_42p = 0
  let c_2_42c = 0
  const resC2_42 = doRdgCalc(4, '68', '', 'oneTotal1', 'totalDebit')
  if (resC2_42.prevYear) {c_2_42p = resC2_42.prevYear }
  if (resC2_42.currYear) {c_2_42c = resC2_42.currYear }
  const nD = {}
  nD.id = 37
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '66, 68'
  nD.col3 = 'Trgovačka roba'
  nD.col4 = ''
  nD.col5 = round(c_2_41p + c_2_42p ,2)
  nD.col6 = round(c_2_41c + c_2_42c ,2)

  
  let c_2_51p = 0
  let c_2_51c = 0
  const resC2_51 = doRdgCalc(4, '37', '', 'oneTotal1', 'totalDebit')
  if (resC2_51.prevYear) {c_2_51p = resC2_51.prevYear }
  if (resC2_51.currYear) {c_2_51c = resC2_51.currYear }
  let c_2_52p = 0
  let c_2_52c = 0
  const resC2_52 = doRdgCalc(4, '67', '', 'oneTotal1', 'totalDebit')
  if (resC2_52.prevYear) {c_2_52p = resC2_52.prevYear }
  if (resC2_52.currYear) {c_2_52c = resC2_52.currYear }
  let c_2_53p = 0
  let c_2_53c = 0
  const resC2_53 = doRdgCalc(2, '687', '', 'oneTotal1', 'totalDebit')
  if (resC2_53.prevYear) {c_2_53p = resC2_53.prevYear }
  if (resC2_53.currYear) {c_2_53c = resC2_53.currYear }
  const nE = {}
  nE.id = 38
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '37, 67, 687'
  nE.col3 = 'Predujmovi za zalihe'
  nE.col4 = ''
  nE.col5 = round(c_2_51p + c_2_52p + c_2_53p ,2)
  nE.col6 = round(c_2_51c + c_2_52c + c_2_53c ,2)

  let c_2_61p = 0
  let c_2_61c = 0
  const resC2_61 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC2_61.prevYear) {c_2_61p = resC2_61.prevYear }
  if (resC2_61.currYear) {c_2_61c = resC2_61.currYear }
  const nF = {}
  nF.id = 39
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '69'
  nF.col3 = 'Dugotrajna imovina namijenjena prodaji'
  nF.col4 = ''
  nF.col5 = round(c_2_61p ,2)
  nF.col6 = round(c_2_61c ,2)

  
  let c_2_71p = 0
  let c_2_71c = 0
  const resC2_71 = doRdgCalc(4, '62', '', 'oneTotal1', 'totalDebit')
  if (resC2_71.prevYear) {c_2_71p = resC2_71.prevYear }
  if (resC2_71.currYear) {c_2_71c = resC2_71.currYear }
  const nG = {}
  nG.id = 40
  nG.header = false
  nG.bold = true
  nG.small = false
  nG.col1 = '7.'
  nG.col2 = '62'
  nG.col3 = 'Biološka imovina'
  nG.col4 = ''
  nG.col5 = round(c_2_71p ,2)
  nG.col6 = round(c_2_71c ,2)

  nI.col5 = round(c_2_11p ,2) + round(c_2_12p ,2) + round(c_2_2p ,2) + round(c_2_3p ,2) + round(c_2_41p ,2) + round(c_2_42p ,2) + round(c_2_51p ,2) + round(c_2_52p ,2) + round(c_2_53p ,2) + round(c_2_61p ,2) + round(c_2_71p ,2)
  nI.col6 = round(c_2_11c ,2) + round(c_2_12c ,2) + round(c_2_2c ,2) + round(c_2_3c ,2) + round(c_2_41c ,2) + round(c_2_42c ,2) + round(c_2_51c ,2) + round(c_2_52c ,2) + round(c_2_53c ,2) + round(c_2_61c ,2) + round(c_2_71c ,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF)
  fullGFI.push(nG)

}

function gfipod8 () {
  const nI = {}
  nI.id = 41
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'C.II.'
  nI.col2 = ''
  nI.col3 = 'POTRAŽIVANJA'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let c_2_1p = 0
  let c_2_1c = 0
  const resC2_1 = doRdgCalc(2, '122', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC2_1.prevYear) {c_2_1p = resC2_1.prevYear }
  if (resC2_1.currYear) {c_2_1c = resC2_1.currYear }
  const nA = {}
  nA.id = 42
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '122'
  nA.col3 = 'Potraživanja od poduzetnika unutar grupe'
  nA.col4 = ''
  nA.col5 = round(c_2_1p ,2)
  nA.col6 = round(c_2_1c ,2)

  let c_2_2p = 0
  let c_2_2c = 0
  const resC2_2 = doRdgCalc(2, '123', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC2_2.prevYear) {c_2_2p = resC2_2.prevYear }
  if (resC2_2.currYear) {c_2_2c = resC2_2.currYear }
  const nB = {}
  nB.id = 43
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '123'
  nB.col3 = 'Potraživanja od društava povezanih sudjelujućim interesom'
  nB.col4 = ''
  nB.col5 = round(c_2_2p ,2)
  nB.col6 = round(c_2_2c ,2)

  let c_2_3p = 0
  let c_2_3c = 0
  const resC2_3 = doRdgCalc(1, '121', '122', 'oneTotal1', 'totalDebit')  // treba mi saldo a ne samo dugovna ili potrazna
  if (resC2_3.prevYear) {c_2_3p = resC2_3.prevYear }
  if (resC2_3.currYear) {c_2_3c = resC2_3.currYear }
  const nC = {}
  nC.id = 44
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '120, 121'
  nC.col3 = 'Potraživanja od kupaca'
  nC.col4 = ''
  nC.col5 = round(c_2_3p ,2)
  nC.col6 = round(c_2_3c ,2)

  let c_2_41p = 0
  let c_2_41c = 0
  const resC2_41 = doRdgCalc(1, '130', '131', 'oneTotal1', 'totalDebit')
  if (resC2_41.prevYear) {c_2_41p = resC2_41.prevYear }
  if (resC2_41.currYear) {c_2_41c = resC2_41.currYear }
  let c_2_42p = 0
  let c_2_42c = 0
  const resC2_42 = doRdgCalc(2, '133', '', 'oneTotal1', 'totalDebit')
  if (resC2_42.prevYear) {c_2_42p = resC2_42.prevYear }
  if (resC2_42.currYear) {c_2_42c = resC2_42.currYear }
  const nD = {}
  nD.id = 45
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '130, 131, 133'
  nD.col3 = 'Potraživanja od zaposlenika i članova poduzetnika'
  nD.col4 = ''
  nD.col5 = round(c_2_41p + c_2_42p ,2)
  nD.col6 = round(c_2_41c + c_2_42c ,2)

  
  let c_2_5p = 0
  let c_2_5c = 0
  const resC2_5 = doRdgCalc(3, '14', '15', 'oneTotal1', 'totalDebit')
  if (resC2_5.prevYear) {c_2_5p = resC2_5.prevYear }
  if (resC2_5.currYear) {c_2_5c = resC2_5.currYear }
  const nE = {}
  nE.id = 46
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '14, 15'
  nE.col3 = 'Potraživanja od države i drugih institucija'
  nE.col4 = ''
  nE.col5 = round(c_2_5p ,2)
  nE.col6 = round(c_2_5c ,2)

  let c_2_61p = 0
  let c_2_61c = 0
  const resC2_61 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC2_61.prevYear) {c_2_61p = resC2_61.prevYear }
  if (resC2_61.currYear) {c_2_61c = resC2_61.currYear }
  let c_2_62p = 0
  let c_2_62c = 0
  const resC2_62 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC2_62.prevYear) {c_2_62p = resC2_62.prevYear }
  if (resC2_62.currYear) {c_2_62c = resC2_62.currYear }
  let c_2_63p = 0
  let c_2_63c = 0
  const resC2_63 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC2_63.prevYear) {c_2_63p = resC2_63.prevYear }
  if (resC2_63.currYear) {c_2_63c = resC2_63.currYear }
  const nF = {}
  nF.id = 47
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '124, 125, 127, 128, 134-138'
  nF.col3 = 'Ostala potraživanja'
  nF.col4 = ''
  nF.col5 = round(c_2_61p + c_2_62p + c_2_63p,2)
  nF.col6 = round(c_2_61c + c_2_62c + c_2_63c,2)

  nI.col5 = round(c_2_1p ,2) + round(c_2_2p ,2) + round(c_2_3p ,2) + round(c_2_41p ,2) + round(c_2_42p ,2) + round(c_2_5p ,2) + round(c_2_61p + c_2_62p + c_2_63p,2)
  nI.col6 = round(c_2_1c ,2) + round(c_2_2c ,2) + round(c_2_3c ,2) + round(c_2_41c ,2) + round(c_2_42c ,2) + round(c_2_1c ,2) + round(c_2_61c + c_2_62c + c_2_63c,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF)

}

function gfipod9 () {

  const nI = {}
  nI.id = 50
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'C.III.'
  nI.col2 = ''
  nI.col3 = 'KRATKOTRAJNA FINANCIJSKA IMOVINA'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let c_3_1p = 0
  let c_3_1c = 0
  const resC3_1 = doRdgCalc(2, '122', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_1.prevYear) {c_3_1p = resC3_1.prevYear }
  if (resC3_1.currYear) {c_3_1c = resC3_1.currYear }
  const nA = {}
  nA.id = 51
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '1100'
  nA.col3 = 'Ulaganja u udjele (dionice) poduzetnika unutar grupe'
  nA.col4 = ''
  nA.col5 = round(c_3_1p ,2)
  nA.col6 = round(c_3_1c ,2)

  let c_3_2p = 0
  let c_3_2c = 0
  const resC3_2 = doRdgCalc(2, '123', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_2.prevYear) {c_3_2p = resC3_2.prevYear }
  if (resC3_2.currYear) {c_3_2c = resC3_2.currYear }
  const nB = {}
  nB.id = 52
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '1101'
  nB.col3 = 'Ulaganja u ostale vrijednosne papire poduzetnika unutar grupe'
  nB.col4 = ''
  nB.col5 = round(c_3_2p ,2)
  nB.col6 = round(c_3_2c ,2)

  let c_3_3p = 0
  let c_3_3c = 0

  const resC3_3 = doRdgCalc(1, '121', '122', 'oneTotal1', 'totalDebit', 'S')  // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_3.prevYear) {c_3_3p = resC3_3.prevYear }
  if (resC3_3.currYear) {c_3_3c = resC3_3.currYear }
  const nC = {}
  nC.id = 53
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '121'
  nC.col3 = 'Dani zajmovi, depoziti i slično poduzetnicima unutar grupe'
  nC.col4 = ''
  nC.col5 = round(c_3_3p ,2)
  nC.col6 = round(c_3_3c ,2)

  let c_3_4p = 0
  let c_3_4c = 0
  const resC3_4 = doRdgCalc(1, '130', '131', 'oneTotal1', 'totalDebit')
  if (resC3_4.prevYear) {c_3_4p = resC3_4.prevYear }
  if (resC3_4.currYear) {c_3_4c = resC3_4.currYear }
  const nD = {}
  nD.id = 54
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '1120'
  nD.col3 = 'Ulaganja u udjele (dionice) društava povezanih sudjelujućim interesom'
  nD.col4 = ''
  nD.col5 = round(c_3_4p ,2)
  nD.col6 = round(c_3_4c ,2)
  
  let c_3_5p = 0
  let c_3_5c = 0
  const resC3_5 = doRdgCalc(3, '14', '15', 'oneTotal1', 'totalDebit')
  if (resC3_5.prevYear) {c_3_5p = resC3_5.prevYear }
  if (resC3_5.currYear) {c_3_5c = resC3_5.currYear }
  const nE = {}
  nE.id = 55
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '1121'
  nE.col3 = 'Ulaganja u ostale vrijednosne papire društava povezanih sudjelujućim interesom'
  nE.col4 = ''
  nE.col5 = round(c_3_5p ,2)
  nE.col6 = round(c_3_5c ,2)

  let c_3_6p = 0
  let c_3_6c = 0
  const resC3_6 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC3_6.prevYear) {c_3_6p = resC3_6.prevYear }
  if (resC3_6.currYear) {c_3_6c = resC3_6.currYear }
  const nF = {}
  nF.id = 56
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '113'
  nF.col3 = 'Dani zajmovi, depoziti i slično društvima povezanim sudjelujućim interesom'
  nF.col4 = ''
  nF.col5 = round(c_3_6p,2)
  nF.col6 = round(c_3_6c,2)

  let c_3_7p = 0
  let c_3_7c = 0
  const resC3_7 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC3_7.prevYear) {c_3_7p = resC3_7.prevYear }
  if (resC3_7.currYear) {c_3_7c = resC3_7.currYear }
  const nG = {}
  nG.id = 57
  nG.header = false
  nG.bold = true
  nG.small = false
  nG.col1 = '7.'
  nG.col2 = '114'
  nG.col3 = 'Ulaganja u vrijednosne papire'
  nG.col4 = ''
  nG.col5 = round(c_3_7p,2)
  nG.col6 = round(c_3_7c,2)

  let c_3_8p = 0
  let c_3_8c = 0
  const resC3_8 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC3_8.prevYear) {c_3_8p = resC3_8.prevYear }
  if (resC3_8.currYear) {c_3_8c = resC3_8.currYear }
  const nH = {}
  nH.id = 58
  nH.header = false
  nH.bold = true
  nH.small = false
  nH.col1 = '8.'
  nH.col2 = '115'
  nH.col3 = 'Dani zajmovi, depoziti i slično'
  nH.col4 = ''
  nH.col5 = round(c_3_8p,2)
  nH.col6 = round(c_3_8c,2)

  let c_3_9p = 0
  let c_3_9c = 0
  const resC3_9 = doRdgCalc(4, '69', '', 'oneTotal1', 'totalDebit')
  if (resC3_9.prevYear) {c_3_9p = resC3_9.prevYear }
  if (resC3_9.currYear) {c_3_9c = resC3_9.currYear }
  const nJ = {}
  nJ.id = 59
  nJ.header = false
  nJ.bold = true
  nJ.small = false
  nJ.col1 = '9.'
  nJ.col2 = '116, 117 i 188'
  nJ.col3 = 'Ostala financijska imovina'
  nJ.col4 = ''
  nJ.col5 = round(c_3_9p,2)
  nJ.col6 = round(c_3_9c,2)

  nI.col5 = round(c_3_1p ,2) + round(c_3_2p ,2) + round(c_3_3p ,2) + round(c_3_4p ,2) + round(c_3_5p ,2) + round(c_3_6p,2)
  nI.col6 = round(c_3_1c ,2) + round(c_3_2c ,2) + round(c_3_3c ,2) + round(c_3_4c ,2) + round(c_3_5c ,2) + round(c_3_6c,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF)
  fullGFI.push(nG)
  fullGFI.push(nH)
  fullGFI.push(nJ)
}

function gfipod10() {
  const nI = {}
  nI.id = 60
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'C.IV.'
  nI.col2 = '10'
  nI.col3 = 'NOVAC U BANCI I BLAGAJNI'
  nI.col4 = ''

  let c_4p = 0
  let c_4c = 0
  const resC4 = doRdgCalc(4, '10', '', 'oneTotal1', 'totalDebit')
  if (resC4.prevYear) {c_4p = resC4.prevYear }
  if (resC4.currYear) {c_4c = resC4.currYear }
  nI.col5 = round(c_4p ,2)
  nI.col6 = round(c_4c ,2)
  fullGFI.push(nI)
}

function gfipod11() {
  const nI = {}
  nI.id = 70
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'D.'
  nI.col2 = '19'
  nI.col3 = 'PLAĆENI TROŠKOVI BUDUĆEG RAZDOBLJA I OBRAČUNATI PRIHODI'
  nI.col4 = ''

  let d_1p = 0
  let d_1c = 0
  const resD1 = doRdgCalc(4, '19', '', 'oneTotal1', 'totalDebit')
  if (resD1.prevYear) {d_1p = resD1.prevYear }
  if (resD1.currYear) {d_1c = resD1.currYear }
  nI.col5 = round(d_1p ,2)
  nI.col6 = round(d_1c ,2)
  fullGFI.push(nI)
}

function gfipod12() {
  const nI = {}
  nI.id = 80
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'E'
  nI.col2 = '0, 1, 3 i 6'
  nI.col3 = 'UKUPNO AKTIVA'
  nI.col4 = ''

  let e_1p = 0
  let e_1c = 0
  const resE1 = doRdgCalc(4, '0', '', 'oneTotal1', 'totalDebit')
  if (resE1.prevYear) {c_4p = resE1.prevYear }
  if (resE1.currYear) {c_4c = resE1.currYear }
  nI.col5 = round(e_1p ,2)
  nI.col6 = round(e_1c ,2)
  fullGFI.push(nI)
}

function gfipod13() {
  const nI = {}
  nI.id = 90
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'F.'
  nI.col2 = '990-994'
  nI.col3 = 'IZVANBILANČNI ZAPISI'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(4, '10', '', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}


function gfipod131() {
  const nI = {}
  nI.id = 91
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = ''
  nI.col2 = ''
  nI.col3 = 'PASIVA'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  // const resF1 = doRdgCalc(4, '29', '', 'oneTotal1', 'totalDebit')
  // if (resF1.prevYear) {f_1p = resF1.prevYear }
  // if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  nI.addDash = true
  fullGFI.push(nI)
}

function gfipod14() {
  const nI = {}
  nI.id = 100
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.'
  nI.col2 = '9'
  nI.col3 = 'KAPITAL I REZERVE'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(4, '9', '', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}

function gfipod15() {
  const nI = {}
  nI.id = 110
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.I'
  nI.col2 = '90'
  nI.col3 = 'TEMELJNI (UPISANI) KAPITAL'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(4, '90', '', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}

function gfipod16() {
  const nI = {}
  nI.id = 120
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.II'
  nI.col2 = '91'
  nI.col3 = 'KAPITALNE REZERVE'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(4, '91', '', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}

function gfipod17() {
  const nI = {}
  nI.id = 130
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.III.'
  nI.col2 = ''
  nI.col3 = 'REZERVE IZ DOBITI'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let a_3_1p = 0
  let a_3_1c = 0
  const resA3_1 = doRdgCalc(2, '920', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA3_1.prevYear) {a_3_1p = resA3_1.prevYear }
  if (resA3_1.currYear) {a_3_1c = resA3_1.currYear }
  const nA = {}
  nA.id = 131
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '920'
  nA.col3 = 'Zakonske rezerve'
  nA.col4 = ''
  nA.col5 = round(a_3_1p ,2)
  nA.col6 = round(a_3_1c ,2)

  let a_3_2p = 0
  let a_3_2c = 0
  const resA3_2 = doRdgCalc(2, '921', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA3_2.prevYear) {a_3_2p = resA3_2.prevYear }
  if (resA3_2.currYear) {a_3_2c = resA3_2.currYear }
  const nB = {}
  nB.id = 132
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '921'
  nB.col3 = 'Rezerve za vlastite dionice'
  nB.col4 = ''
  nB.col5 = round(a_3_2p ,2)
  nB.col6 = round(a_3_2c ,2)

  let a_3_3p = 0
  let a_3_3c = 0
  const resA3_3 = doRdgCalc(1, '922', '', 'oneTotal1', 'totalDebit')  // treba mi saldo a ne samo dugovna ili potrazna
  if (resA3_3.prevYear) {a_3_3p = resA3_3.prevYear }
  if (resA3_3.currYear) {a_3_3c = resA3_3.currYear }
  const nC = {}
  nC.id = 133
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '922'
  nC.col3 = 'Vlastite dionice i udjeli (odbitna stavka)'
  nC.col4 = ''
  nC.col5 = round(a_3_3p ,2)
  nC.col6 = round(a_3_3c ,2)

  let a_3_4p = 0
  let a_3_4c = 0
  const resA3_4 = doRdgCalc(2, '923', '', 'oneTotal1', 'totalDebit')
  if (resA3_4.prevYear) {a_3_4p = resA3_4.prevYear }
  if (resA3_4.currYear) {a_3_4c = resA3_4.currYear }
  const nD = {}
  nD.id = 134
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '923'
  nD.col3 = 'Statutarne rezerve'
  nD.col4 = ''
  nD.col5 = round(a_3_4p ,2)
  nD.col6 = round(a_3_4c ,2)
  
  let a_3_5p = 0
  let a_3_5c = 0
  const resA3_5 = doRdgCalc(2, '924', '', 'oneTotal1', 'totalDebit')
  if (resA3_5.prevYear) {a_3_5p = resA3_5.prevYear }
  if (resA3_5.currYear) {a_3_5c = resA3_5.currYear }
  const nE = {}
  nE.id = 135
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '924'
  nE.col3 = 'Ostale rezerve'
  nE.col4 = ''
  nE.col5 = round(a_3_5p ,2)
  nE.col6 = round(a_3_5c ,2)

  nI.col5 = round(a_3_1p ,2) + round(a_3_2p ,2) + round(a_3_3p ,2) + round(a_3_4p ,2) + round(a_3_5p ,2)
  nI.col6 = round(a_3_1c ,2) + round(a_3_2c ,2) + round(a_3_3c ,2) + round(a_3_4c ,2) + round(a_3_5c ,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)

}

function gfipod18() {
  const nI = {}
  nI.id = 140
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.IV'
  nI.col2 = '930, 932 - 935'
  nI.col3 = 'REVALORIZACIJSKE REZERVE'
  nI.col4 = ''

  let a_41p = 0
  let a_41c = 0
  const resA41 = doRdgCalc(2, '930', '', 'oneTotal1', 'totalDebit')
  if (resA41.prevYear) {a_41p = resA41.prevYear }
  if (resA41.currYear) {a_41c = resA41.currYear }

  let a_42p = 0
  let a_42c = 0
  const resA42 = doRdgCalc(1, '932', '935', 'oneTotal1', 'totalDebit')
  if (resA42.prevYear) {a_42p = resA42.prevYear }
  if (resA42.currYear) {a_42c = resA42.currYear }

  nI.col5 = round(a_41p ,2) + round(a_42p ,2)
  nI.col6 = round(a_41c ,2) + round(a_42c ,2)
  fullGFI.push(nI)

}

function gfipod19() {
  const nI = {}
  nI.id = 150
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.V.'
  nI.col2 = ''
  nI.col3 = 'REZERVE FER VRIJEDNOSTI'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let a_5_1p = 0
  let a_5_1c = 0
  const resA5_1 = doRdgCalc(5, '9310', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA5_1.prevYear) {a_5_1p = resA5_1.prevYear }
  if (resA5_1.currYear) {a_5_1c = resA5_1.currYear }
  const nA = {}
  nA.id = 151
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '9310'
  nA.col3 = 'Fer vrijednost financijske imovine raspoložive za prodaju'
  nA.col4 = ''
  nA.col5 = round(a_5_1p ,2)
  nA.col6 = round(a_5_1c ,2)

  let a_5_2p = 0
  let a_5_2c = 0
  const resA5_2 = doRdgCalc(5, '9312', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA5_2.prevYear) {a_5_2p = resA5_2.prevYear }
  if (resA5_2.currYear) {a_5_2c = resA5_2.currYear }
  const nB = {}
  nB.id = 152
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '9312'
  nB.col3 = 'Učinkoviti dio zaštite novčanih tokova'
  nB.col4 = ''
  nB.col5 = round(a_5_2p ,2)
  nB.col6 = round(a_5_2c ,2)

  let a_5_3p = 0
  let a_5_3c = 0
  const resA5_3 = doRdgCalc(5, '9313', '', 'oneTotal1', 'totalDebit')  // treba mi saldo a ne samo dugovna ili potrazna
  if (resA5_3.prevYear) {a_5_3p = resA5_3.prevYear }
  if (resA5_3.currYear) {a_5_3c = resA5_3.currYear }
  const nC = {}
  nC.id = 153
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '9313'
  nC.col3 = 'Učinkoviti dio zaštite neto ulaganja u inozemstvu'
  nC.col4 = ''
  nC.col5 = round(a_5_3p ,2)
  nC.col6 = round(a_5_3c ,2)

  nI.col5 = round(a_5_1p ,2) + round(a_5_2p ,2) + round(a_5_3p ,2)
  nI.col6 = round(a_5_1c ,2) + round(a_5_2c ,2) + round(a_5_3c ,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)

}

function gfipod20() {
  const nI = {}
  nI.id = 160
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.VI.'
  nI.col2 = '94'
  nI.col3 = 'ZADRŽANA DOBIT ILI PRENESENI GUBITAK'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let a_6_1p = 0
  let a_6_1c = 0
  const resA6_1 = doRdgCalc(2, '940', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA6_1.prevYear) {a_6_1p = resA6_1.prevYear }
  if (resA6_1.currYear) {a_6_1c = resA6_1.currYear }
  const nA = {}
  nA.id = 161
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '940'
  nA.col3 = 'Zadržana dobit'
  nA.col4 = ''
  nA.col5 = round(a_6_1p ,2)
  nA.col6 = round(a_6_1c ,2)

  let a_6_2p = 0
  let a_6_2c = 0
  const resA6_2 = doRdgCalc(2, '941', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA6_2.prevYear) {a_6_2p = resA6_2.prevYear }
  if (resA6_2.currYear) {a_6_2c = resA6_2.currYear }
  const nB = {}
  nB.id = 162
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '941'
  nB.col3 = 'Preneseni gubitak'
  nB.col4 = ''
  nB.col5 = round(a_6_2p ,2)
  nB.col6 = round(a_6_2c ,2)

  nI.col5 = round(a_6_1p ,2) + round(a_6_2p ,2)
  nI.col6 = round(a_6_1c ,2) + round(a_6_2c ,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)

}

function gfipod21() {
  const nI = {}
  nI.id = 170
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.VII.'
  nI.col2 = '95'
  nI.col3 = 'DOBIT ILI GUBITAK POSLOVNE GODINE'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let a_7_1p = 0
  let a_7_1c = 0
  const resA7_1 = doRdgCalc(2, '950', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA7_1.prevYear) {a_7_1p = resA7_1.prevYear }
  if (resA7_1.currYear) {a_7_1c = resA7_1.currYear }
  const nA = {}
  nA.id = 171
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '950'
  nA.col3 = 'Dobit poslovne godine'
  nA.col4 = ''
  nA.col5 = round(a_7_1p ,2)
  nA.col6 = round(a_7_1c ,2)

  let a_7_2p = 0
  let a_7_2c = 0
  const resA7_2 = doRdgCalc(2, '951', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resA7_2.prevYear) {a_7_2p = resA7_2.prevYear }
  if (resA7_2.currYear) {a_7_2c = resA7_2.currYear }
  const nB = {}
  nB.id = 172
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '951'
  nB.col3 = 'Gubitak poslovne godine'
  nB.col4 = ''
  nB.col5 = round(a_7_2p ,2)
  nB.col6 = round(a_7_2c ,2)

  nI.col5 = round(a_7_1p ,2) + round(a_7_2p ,2)
  nI.col6 = round(a_7_1c ,2) + round(a_7_2c ,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)

}

function gfipod22() {
  const nI = {}
  nI.id = 180
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'A.VIII'
  nI.col2 = '96'
  nI.col3 = 'MANJINSKI (NEKONTROLIRAJUĆI) INTERES'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(4, '96', '', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}

function gfipod23 () {
  const nI = {}
  nI.id = 190
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'C.'
  nI.col2 = '25'
  nI.col3 = 'DUGOROČNE OBVEZE'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let c_3_1p = 0
  let c_3_1c = 0
  const resC3_1 = doRdgCalc(5, '2500', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_1.prevYear) {c_3_1p = resC3_1.prevYear }
  if (resC3_1.currYear) {c_3_1c = resC3_1.currYear }
  const nA = {}
  nA.id = 191
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '2500'
  nA.col3 = 'Obveze prema poduzetnicima unutar grupe'
  nA.col4 = ''
  nA.col5 = round(c_3_1p ,2)
  nA.col6 = round(c_3_1c ,2)

  let c_3_2p = 0
  let c_3_2c = 0
  const resC3_2 = doRdgCalc(5, '2501', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_2.prevYear) {c_3_2p = resC3_2.prevYear }
  if (resC3_2.currYear) {c_3_2c = resC3_2.currYear }
  let c_3_22p = 0
  let c_3_22c = 0
  const resC3_22 = doRdgCalc(5, '2501', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_22.prevYear) {c_3_22p = resC3_22.prevYear }
  if (resC3_22.currYear) {c_3_22c = resC3_22.currYear }
  const nB = {}
  nB.id = 192
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '2501, 2502'
  nB.col3 = 'Obveze za zajmove, depozite i slično poduzetnika unutar grupe'
  nB.col4 = ''
  nB.col5 = round(c_3_2p ,2) + round(c_3_22p ,2)
  nB.col6 = round(c_3_2c ,2) + round(c_3_22c ,2)

  let c_3_3p = 0
  let c_3_3c = 0
  const resC3_3 = doRdgCalc(2, '257', '', 'oneTotal1', 'totalDebit', 'S')  // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_3.prevYear) {c_3_3p = resC3_3.prevYear }
  if (resC3_3.currYear) {c_3_3c = resC3_3.currYear }
  const nC = {}
  nC.id = 193
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '257'
  nC.col3 = 'Obveze prema društvima povezanim sudjelujućim interesom'
  nC.col4 = ''
  nC.col5 = round(c_3_3p ,2)
  nC.col6 = round(c_3_3c ,2)

  let c_3_4p = 0
  let c_3_4c = 0
  const resC3_4 = doRdgCalc(5, '2510', '', 'oneTotal1', 'totalDebit')
  if (resC3_4.prevYear) {c_3_4p = resC3_4.prevYear }
  if (resC3_4.currYear) {c_3_4c = resC3_4.currYear }
  const nD = {}
  nD.id = 194
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '2510'
  nD.col3 = 'Obveze za zajmove, depozite i slično društava povezanih sudjelujućim interesom'
  nD.col4 = ''
  nD.col5 = round(c_3_4p ,2)
  nD.col6 = round(c_3_4c ,2)
  
  let c_3_5p = 0
  let c_3_5c = 0
  const resC3_5 = doRdgCalc(5, '2511', '', 'oneTotal1', 'totalDebit')
  if (resC3_5.prevYear) {c_3_5p = resC3_5.prevYear }
  if (resC3_5.currYear) {c_3_5c = resC3_5.currYear }
  const nE = {}
  nE.id = 195
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '2511-25119'
  nE.col3 = 'Obveze za zajmove, depozite i slično'
  nE.col4 = ''
  nE.col5 = round(c_3_5p ,2)
  nE.col6 = round(c_3_5c ,2)

  let c_3_6p = 0
  let c_3_6c = 0
  const resC3_6 = doRdgCalc(2, '252', '', 'oneTotal1', 'totalDebit')
  if (resC3_6.prevYear) {c_3_6p = resC3_6.prevYear }
  if (resC3_6.currYear) {c_3_6c = resC3_6.currYear }
  const nF = {}
  nF.id = 196
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '252'
  nF.col3 = 'Obveze prema bankama i drugim financijskim institucijama'
  nF.col4 = ''
  nF.col5 = round(c_3_6p,2)
  nF.col6 = round(c_3_6c,2)

  let c_3_7p = 0
  let c_3_7c = 0
  const resC3_7 = doRdgCalc(2, '254', '', 'oneTotal1', 'totalDebit')
  if (resC3_7.prevYear) {c_3_7p = resC3_7.prevYear }
  if (resC3_7.currYear) {c_3_7c = resC3_7.currYear }
  const nG = {}
  nG.id = 197
  nG.header = false
  nG.bold = true
  nG.small = false
  nG.col1 = '7.'
  nG.col2 = '254'
  nG.col3 = 'Obveze za predujmove'
  nG.col4 = ''
  nG.col5 = round(c_3_7p,2)
  nG.col6 = round(c_3_7c,2)

  let c_3_8p = 0
  let c_3_8c = 0
  const resC3_8 = doRdgCalc(2, '255', '', 'oneTotal1', 'totalDebit')
  if (resC3_8.prevYear) {c_3_8p = resC3_8.prevYear }
  if (resC3_8.currYear) {c_3_8c = resC3_8.currYear }
  const nH = {}
  nH.id = 198
  nH.header = false
  nH.bold = true
  nH.small = false
  nH.col1 = '8.'
  nH.col2 = '255'
  nH.col3 = 'Obveze prema dobavljačima'
  nH.col4 = ''
  nH.col5 = round(c_3_8p,2)
  nH.col6 = round(c_3_8c,2)

  let c_3_9p = 0
  let c_3_9c = 0
  const resC3_9 = doRdgCalc(2, '256', '', 'oneTotal1', 'totalDebit')
  if (resC3_9.prevYear) {c_3_9p = resC3_9.prevYear }
  if (resC3_9.currYear) {c_3_9c = resC3_9.currYear }
  const nJ = {}
  nJ.id = 199
  nJ.header = false
  nJ.bold = true
  nJ.small = false
  nJ.col1 = '9.'
  nJ.col2 = '256'
  nJ.col3 = 'Obveze po vrijednosnim papirima'
  nJ.col4 = ''
  nJ.col5 = round(c_3_9p,2)
  nJ.col6 = round(c_3_9c,2)

  let c_3_10p = 0
  let c_3_10c = 0
  const resC3_10 = doRdgCalc(2, '253', '', 'oneTotal1', 'totalDebit')
  if (resC3_10.prevYear) {c_3_10p = resC3_10.prevYear }
  if (resC3_10.currYear) {c_3_10c = resC3_10.currYear }
  let c_3_102p = 0
  let c_3_102c = 0
  const resC3_102 = doRdgCalc(2, '258', '', 'oneTotal1', 'totalDebit')
  if (resC3_102.prevYear) {c_3_102p = resC3_102.prevYear }
  if (resC3_102.currYear) {c_3_102c = resC3_102.currYear }
  let c_3_103p = 0
  let c_3_103c = 0
  const resC3_103 = doRdgCalc(2, '259', '', 'oneTotal1', 'totalDebit')
  if (resC3_103.prevYear) {c_3_103p = resC3_103.prevYear }
  if (resC3_103.currYear) {c_3_103c = resC3_103.currYear }
  const nK = {}
  nK.id = 200
  nK.header = false
  nK.bold = true
  nK.small = false
  nK.col1 = '10.'
  nK.col2 = '253, 258 i 259'
  nK.col3 = 'Ostale dugoročne obveze'
  nK.col4 = ''
  nK.col5 = round(c_3_10p,2) + round(c_3_102p,2) + round(c_3_103p,2)
  nK.col6 = round(c_3_10c,2) + round(c_3_102c,2) + round(c_3_103c,2)

  let c_3_11p = 0
  let c_3_11c = 0
  const resC3_11 = doRdgCalc(2, '260', '', 'oneTotal1', 'totalDebit')
  if (resC3_11.prevYear) {c_3_11p = resC3_11.prevYear }
  if (resC3_11.currYear) {c_3_11c = resC3_11.currYear }
  const nL = {}
  nL.id = 201
  nL.header = false
  nL.bold = true
  nL.small = false
  nL.col1 = '11.'
  nL.col2 = '260'
  nL.col3 = 'Odgođena porezna obveza'
  nL.col4 = ''
  nL.col5 = round(c_3_11p,2)
  nL.col6 = round(c_3_11c,2)


  nI.col5 = round(c_3_1p ,2) + round(c_3_2p ,2) + round(c_3_22p ,2)+ round(c_3_3p ,2) + round(c_3_4p ,2) + round(c_3_5p ,2) + round(c_3_6p,2) + round(c_3_7p ,2) + round(c_3_8p ,2) + round(c_3_9p ,2) + round(c_3_10p,2) + round(c_3_102p,2) + round(c_3_103p,2) + round(c_3_11p,2)
  nI.col6 = round(c_3_1c ,2) + round(c_3_2c ,2) + round(c_3_22c ,2)+ round(c_3_3c ,2) + round(c_3_4c ,2) + round(c_3_5c ,2) + round(c_3_6c,2) + round(c_3_7c ,2) + round(c_3_8c ,2) + round(c_3_9c ,2) + round(c_3_10c,2) + round(c_3_102c,2) + round(c_3_103c,2) + round(c_3_11c,2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF)
  fullGFI.push(nG)
  fullGFI.push(nH)
  fullGFI.push(nJ)
  fullGFI.push(nK)
  fullGFI.push(nL)
}

function gfipod24 () {
  const nI = {}
  nI.id = 250
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'D.'
  nI.col2 = ''
  nI.col3 = 'KRATKOROČNE OBVEZE'
  nI.col4 = ''
  nI.col5 = null
  nI.col6 = null

  let c_3_1p = 0
  let c_3_1c = 0
  const resC3_1 = doRdgCalc(5, '2000', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_1.prevYear) {c_3_1p = resC3_1.prevYear }
  if (resC3_1.currYear) {c_3_1c = resC3_1.currYear }
  let c_3_1_2p = 0
  let c_3_1_2c = 0
  const resC3_1_2 = doRdgCalc(5, '2002', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_1_2.prevYear) {c_3_1_2p = resC3_1_2.prevYear }
  if (resC3_1_2.currYear) {c_3_1_2c = resC3_1_2.currYear }
  const nA = {}
  nA.id = 251
  nA.header = false
  nA.bold = true
  nA.small = false
  nA.col1 = '1.'
  nA.col2 = '2000, 2002'
  nA.col3 = 'Obveze prema poduzetnicima unutar grupe'
  nA.col4 = ''
  nA.col5 = round(c_3_1p + c_3_1_2p ,2)
  nA.col6 = round(c_3_1c + c_3_1_2c ,2)

  let c_3_2p = 0
  let c_3_2c = 0
  const resC3_2 = doRdgCalc(5, '2001', '', 'oneTotal1', 'totalDebit') // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_2.prevYear) {c_3_2p = resC3_2.prevYear }
  if (resC3_2.currYear) {c_3_2c = resC3_2.currYear }
  const nB = {}
  nB.id = 252
  nB.header = false
  nB.bold = true
  nB.small = false
  nB.col1 = '2.'
  nB.col2 = '2001'
  nB.col3 = 'Obveze za zajmove, depozite i slično poduzetnika unutar grupe'
  nB.col4 = ''
  nB.col5 = round(c_3_2p ,2) 
  nB.col6 = round(c_3_2c ,2)

  let c_3_3p = 0
  let c_3_3c = 0
  const resC3_3 = doRdgCalc(5, '2130', '', 'oneTotal1', 'totalDebit', 'S')  // treba mi saldo a ne samo dugovna ili potrazna
  if (resC3_3.prevYear) {c_3_3p = resC3_3.prevYear }
  if (resC3_3.currYear) {c_3_3c = resC3_3.currYear }
  const nC = {}
  nC.id = 253
  nC.header = false
  nC.bold = true
  nC.small = false
  nC.col1 = '3.'
  nC.col2 = '2130'
  nC.col3 = 'Obveze prema društvima povezanim sudjelujućim interesom'
  nC.col4 = ''
  nC.col5 = round(c_3_3p ,2)
  nC.col6 = round(c_3_3c ,2)

  let c_3_4p = 0
  let c_3_4c = 0
  const resC3_4 = doRdgCalc(5, '2131', '', 'oneTotal1', 'totalDebit')
  if (resC3_4.prevYear) {c_3_4p = resC3_4.prevYear }
  if (resC3_4.currYear) {c_3_4c = resC3_4.currYear }
  const nD = {}
  nD.id = 254
  nD.header = false
  nD.bold = true
  nD.small = false
  nD.col1 = '4.'
  nD.col2 = '2131'
  nD.col3 = 'Obveze za zajmove, depozite i slično društava povezanih sudjelujućim interesom'
  nD.col4 = ''
  nD.col5 = round(c_3_4p ,2)
  nD.col6 = round(c_3_4c ,2)
  
  let c_3_5p = 0
  let c_3_5c = 0
  const resC3_5 = doRdgCalc(2, '214', '', 'oneTotal1', 'totalDebit')
  if (resC3_5.prevYear) {c_3_5p = resC3_5.prevYear }
  if (resC3_5.currYear) {c_3_5c = resC3_5.currYear }
  const nE = {}
  nE.id = 255
  nE.header = false
  nE.bold = true
  nE.small = false
  nE.col1 = '5.'
  nE.col2 = '214'
  nE.col3 = 'Obveze za zajmove, depozite i slično'
  nE.col4 = ''
  nE.col5 = round(c_3_5p ,2)
  nE.col6 = round(c_3_5c ,2)

  let c_3_6p = 0
  let c_3_6c = 0
  const resC3_6 = doRdgCalc(2, '215', '', 'oneTotal1', 'totalDebit')
  if (resC3_6.prevYear) {c_3_6p = resC3_6.prevYear }
  if (resC3_6.currYear) {c_3_6c = resC3_6.currYear }
  const nF = {}
  nF.id = 256
  nF.header = false
  nF.bold = true
  nF.small = false
  nF.col1 = '6.'
  nF.col2 = '215'
  nF.col3 = 'Obveze prema bankama i drugim financijskim institucijama'
  nF.col4 = ''
  nF.col5 = round(c_3_6p,2)
  nF.col6 = round(c_3_6c,2)

  let c_3_7p = 0
  let c_3_7c = 0
  const resC3_7 = doRdgCalc(2, '225', '', 'oneTotal1', 'totalDebit')
  if (resC3_7.prevYear) {c_3_7p = resC3_7.prevYear }
  if (resC3_7.currYear) {c_3_7c = resC3_7.currYear }
  const nG = {}
  nG.id = 257
  nG.header = false
  nG.bold = true
  nG.small = false
  nG.col1 = '7.'
  nG.col2 = '225'
  nG.col3 = 'Obveze za predujmove'
  nG.col4 = ''
  nG.col5 = round(c_3_7p,2)
  nG.col6 = round(c_3_7c,2)

  let c_3_8p = 0
  let c_3_8c = 0
  const resC3_8 = doRdgCalc(2, '220', '', 'oneTotal1', 'totalDebit')
  if (resC3_8.prevYear) {c_3_8p = resC3_8.prevYear }
  if (resC3_8.currYear) {c_3_8c = resC3_8.currYear }
  let c_3_8_2p = 0
  let c_3_8_2c = 0
  const resC3_8_2 = doRdgCalc(2, '220', '', 'oneTotal1', 'totalDebit')
  if (resC3_8_2.prevYear) {c_3_8_2p = resC3_8_2.prevYear }
  if (resC3_8_2.currYear) {c_3_8_2c = resC3_8_2.currYear }
  let c_3_8_3p = 0
  let c_3_8_3c = 0
  const resC3_8_3 = doRdgCalc(2, '220', '', 'oneTotal1', 'totalDebit')
  if (resC3_8_3.prevYear) {c_3_8_3p = resC3_8_3.prevYear }
  if (resC3_8_3.currYear) {c_3_8_3c = resC3_8_3.currYear }
  let c_3_8_4p = 0
  let c_3_8_4c = 0
  const resC3_8_4 = doRdgCalc(2, '220', '', 'oneTotal1', 'totalDebit')
  if (resC3_8_4.prevYear) {c_3_8_4p = resC3_8_4.prevYear }
  if (resC3_8_4.currYear) {c_3_8_4c = resC3_8_4.currYear }
  const nH = {}
  nH.id = 258
  nH.header = false
  nH.bold = true
  nH.small = false
  nH.col1 = '8.'
  nH.col2 = '220, 221, 222, 223'
  nH.col3 = 'Obveze prema dobavljačima'
  nH.col4 = ''
  nH.col5 = round(c_3_8p + c_3_8_2p + c_3_8_3p + c_3_8_4p ,2)
  nH.col6 = round(c_3_8c + c_3_8_2c + c_3_8_3c + c_3_8_4c ,2)

  let c_3_9p = 0
  let c_3_9c = 0
  const resC3_9 = doRdgCalc(2, '210', '', 'oneTotal1', 'totalDebit')
  if (resC3_9.prevYear) {c_3_9p = resC3_9.prevYear }
  if (resC3_9.currYear) {c_3_9c = resC3_9.currYear }
  let c_3_9_2p = 0
  let c_3_9_2c = 0
  const resC3_9_2 = doRdgCalc(2, '210', '', 'oneTotal1', 'totalDebit')
  if (resC3_9_2.prevYear) {c_3_9_2p = resC3_9_2.prevYear }
  if (resC3_9_2.currYear) {c_3_9_2c = resC3_9_2.currYear }
  let c_3_9_3p = 0
  let c_3_9_3c = 0
  const resC3_9_3 = doRdgCalc(2, '210', '', 'oneTotal1', 'totalDebit')
  if (resC3_9_3.prevYear) {c_3_9_3p = resC3_9_3.prevYear }
  if (resC3_9_3.currYear) {c_3_9_3c = resC3_9_3.currYear }
  const nJ = {}
  nJ.id = 259
  nJ.header = false
  nJ.bold = true
  nJ.small = false
  nJ.col1 = '9.'
  nJ.col2 = '210, 211, 212'
  nJ.col3 = 'Obveze po vrijednosnim papirima'
  nJ.col4 = ''
  nJ.col5 = round(c_3_9p + c_3_9_2p + c_3_9_3p,2)
  nJ.col6 = round(c_3_9c + c_3_9_2c + c_3_9_3c,2)

  let c_3_10p = 0
  let c_3_10c = 0
  const resC3_10 = doRdgCalc(2, '230', '', 'oneTotal1', 'totalDebit')
  if (resC3_10.prevYear) {c_3_10p = resC3_10.prevYear }
  if (resC3_10.currYear) {c_3_10c = resC3_10.currYear }
  const nK = {}
  nK.id = 260
  nK.header = false
  nK.bold = true
  nK.small = false
  nK.col1 = '10.'
  nK.col2 = '230'
  nK.col3 = 'Obveze prema zaposlenicima'
  nK.col4 = ''
  nK.col5 = round(c_3_10p,2) 
  nK.col6 = round(c_3_10c,2)

  let c_3_11p = 0
  let c_3_11c = 0
  const resC3_11 = doRdgCalc(4, '24', '', 'oneTotal1', 'totalDebit')
  if (resC3_11.prevYear) {c_3_11p = resC3_11.prevYear }
  if (resC3_11.currYear) {c_3_11c = resC3_11.currYear }
  const nL = {}
  nL.id = 261
  nL.header = false
  nL.bold = true
  nL.small = false
  nL.col1 = '11.'
  nL.col2 = '24'
  nL.col3 = 'Obveze za poreze, doprinose i slična davanja'
  nL.col4 = ''
  nL.col5 = round(c_3_11p,2)
  nL.col6 = round(c_3_11c,2)

  let c_3_12p = 0
  let c_3_12c = 0
  const resC3_12 = doRdgCalc(2, '201', '', 'oneTotal1', 'totalDebit')
  if (resC3_12.prevYear) {c_3_12p = resC3_12.prevYear }
  if (resC3_12.currYear) {c_3_12c = resC3_12.currYear }
  const nM = {}
  nM.id = 262
  nM.header = false
  nM.bold = true
  nM.small = false
  nM.col1 = '12.'
  nM.col2 = '201'
  nM.col3 = 'Obveze s osnove udjela u rezultatu'
  nM.col4 = ''
  nM.col5 = round(c_3_12p,2)
  nM.col6 = round(c_3_12c,2)

  let c_3_13p = 0
  let c_3_13c = 0
  const resC3_13 = doRdgCalc(2, '218', '', 'oneTotal1', 'totalDebit')
  if (resC3_13.prevYear) {c_3_13p = resC3_13.prevYear }
  if (resC3_13.currYear) {c_3_13c = resC3_13.currYear }
  const nN = {}
  nN.id = 263
  nN.header = false
  nN.bold = true
  nN.small = false
  nN.col1 = '13.'
  nN.col2 = '218'
  nN.col3 = 'Obveze po osnovi dugotrajne imovine namijenjene prodaji'
  nN.col4 = ''
  nN.col5 = round(c_3_13p,2)
  nN.col6 = round(c_3_13c,2)

  let c_3_14p = 0
  let c_3_14c = 0
  const resC3_14 = doRdgCalc(2, '216', '', 'oneTotal1', 'totalDebit')
  if (resC3_14.prevYear) {c_3_14p = resC3_14.prevYear }
  if (resC3_14.currYear) {c_3_14c = resC3_14.currYear }
  let c_3_14_2p = 0
  let c_3_14_2c = 0
  const resC3_14_2 = doRdgCalc(2, '217', '', 'oneTotal1', 'totalDebit')
  if (resC3_14_2.prevYear) {c_3_14_2p = resC3_14_2.prevYear }
  if (resC3_14_2.currYear) {c_3_14_2c = resC3_14_2.currYear }
  let c_3_14_3p = 0
  let c_3_14_3c = 0
  const resC3_14_3 = doRdgCalc(2, '224', '', 'oneTotal1', 'totalDebit')
  if (resC3_14_3.prevYear) {c_3_14_3p = resC3_14_3.prevYear }
  if (resC3_14_3.currYear) {c_3_14_3c = resC3_14_3.currYear }
  let c_3_14_4p = 0
  let c_3_14_4c = 0
  const resC3_14_4 = doRdgCalc(2, '231-239', '', 'oneTotal1', 'totalDebit')
  if (resC3_14_4.prevYear) {c_3_14_4p = resC3_14_4.prevYear }
  if (resC3_14_4.currYear) {c_3_14_4c = resC3_14_4.currYear }
  const nO = {}
  nO.id = 264
  nO.header = false
  nO.bold = true
  nO.small = false
  nO.col1 = '14.'
  nO.col2 = '216, 217, 224, 231 - 239'
  nO.col3 = 'Ostale kratkoročne obveze'
  nO.col4 = ''
  nO.col5 = round(c_3_14p + c_3_14_2p + c_3_14_3p + c_3_14_4p ,2)
  nO.col6 = round(c_3_14c + c_3_14_2c + c_3_14_3c + c_3_14_4c ,2)


  nI.col5 = round(nA.col5 + nB.col5 + nC.col5 + nD.col5 + nE.col5 + nF.col5 + nG.col5 + nH.col5 + nJ.col5 + nK.col5 + nL.col5 + nM.col5 + nN.col5 + nO.col5, 2)
  nI.col6 = round(nA.col6 + nB.col6 + nC.col6 + nD.col6 + nE.col6 + nF.col6 + nG.col6 + nH.col6 + nJ.col6 + nK.col6 + nL.col6 + nM.col6 + nN.col6 + nO.col6, 2)

  fullGFI.push(nI)
  
  fullGFI.push(nA)
  fullGFI.push(nB)
  fullGFI.push(nC)
  fullGFI.push(nD)
  fullGFI.push(nE)
  fullGFI.push(nF)
  fullGFI.push(nG)
  fullGFI.push(nH)
  fullGFI.push(nJ)
  fullGFI.push(nK)
  fullGFI.push(nL)
  fullGFI.push(nM)
  fullGFI.push(nN)
  fullGFI.push(nO)
}

function gfipod25() {
  const nI = {}
  nI.id = 300
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'E.'
  nI.col2 = '29'
  nI.col3 = 'ODGOĐENO PLAĆANJE TROŠKOVA I PRIHOD BUDUĆEG RAZDOBLJA'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(4, '29', '', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}

function gfipod26() {
  const nI = {}
  nI.id = 310
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'F.'
  nI.col2 = '2 i 9'
  nI.col3 = 'UKUPNO - PASIVA'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(4, '91', '', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}

function gfipod27() {
  const nI = {}
  nI.id = 320
  nI.header = true
  nI.bold = true
  nI.small = false
  nI.col1 = 'G.'
  nI.col2 = '995 - 999'
  nI.col3 = 'IZVANBILANČNI ZAPISI'
  nI.col4 = ''

  let f_1p = 0
  let f_1c = 0
  const resF1 = doRdgCalc(1, '995', '999', 'oneTotal1', 'totalDebit')
  if (resF1.prevYear) {f_1p = resF1.prevYear }
  if (resF1.currYear) {f_1c = resF1.currYear }
  nI.col5 = round(f_1p ,2)
  nI.col6 = round(f_1c ,2)
  fullGFI.push(nI)
}


function doRdgCalc(type, from, to, currYearField, prevYearField, status) {
  const retObj ={}
  retObj.prevYear = 0
  retObj.currYear = 0

  function calculateSum(array, property) {
    const total = array.reduce((accumulator, object) => {
      return accumulator + object[property];
    }, 0);
    //const total2 = Math.abs(total)
    //console.log('total', total2)
    return total;
  }

  if (type === 1) {
    // go from to to

    const fCond = pItems.filter(item => (item.oneCond >= from && item.oneCond <= to) && item.itLine === 'Sin3.ukupno:')
    if (fCond && fCond.length > 0) {
      retObj.currYear = calculateSum(fCond, currYearField)
    }
    const fCond2 = pOldItems.filter(item => (item.account3 >= from && item.account3 <= to))
    if (fCond2 && fCond2.length > 0) {
      retObj.prevYear = round(calculateSum(fCond2, prevYearField) / exRate , 2)
    }
  }
  if (type === 2) {
    // just use from
    const fCond = pItems.filter(item => item.oneCond === from && item.itLine === 'Sin3.ukupno:')
    if (fCond && fCond.length > 0) {
      retObj.currYear = calculateSum(fCond, currYearField)
    }
    const fCond2 = pOldItems.filter(item => item.account3 === from)
    if (fCond2 && fCond2.length > 0) {
      retObj.prevYear = round(calculateSum(fCond2, prevYearField)  / exRate,2) 
    }
  }

  if (type === 3) {
    // go from to to
    const fCond = pItems.filter(item => (item.oneCond >= from && item.oneCond <= to) && item.itLine === 'Sin.ukupno:')
    if (fCond && fCond.length > 0) {
      retObj.currYear = calculateSum(fCond, currYearField)
    }
    const fCond2 = pOldItems.filter(item => (item.account2 >= from && item.account2 <= to))
    if (fCond2 && fCond2.length > 0) {
      retObj.prevYear = round(calculateSum(fCond2, prevYearField) / exRate ,2)
    }
  }

  if (type === 4) {
    // go from to to
    const fCond = pItems.filter(item => item.oneCond === from  && item.itLine === 'Sin.ukupno:')
    if (fCond && fCond.length > 0) {
      retObj.currYear = calculateSum(fCond, currYearField)
    }
    const fCond2 = pOldItems.filter(item => item.account2 === from )
    if (fCond2 && fCond2.length > 0) {
      retObj.prevYear = round(calculateSum(fCond2, prevYearField) / exRate, 2)
    }
  }

  if (type === 5) {
    // just use from
    const fCond = pItems.filter(item => item.account4 === from)
    if (fCond && fCond.length > 0) {
      retObj.currYear = calculateSum(fCond, currYearField)
    }
    const fCond2 = pOldItems.filter(item => item.account4 === from)
    if (fCond2 && fCond2.length > 0) {
      retObj.prevYear = round(calculateSum(fCond2, prevYearField)  / exRate,2) 
    }
  }
return retObj
}




export default doAll